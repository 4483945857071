import { cloneDeep } from 'lodash';
import { computeStaffBenefits } from '@common/invoice/staff-cost';

// fields that should be displayed individually
const multipleFieldFilters = new Set(['locationId', 'tripEventId', 'tripTypeId', 'vehicleTypeId']);

const filterConfig = {
  batchId: ({ value }) => ({ text: `Batch # ${value}`, value }),
  budgetCode: ({ value }) => ({ text: `Budget Code: ${value}`, value }),
  destinationId: ({ value, rootGetters }) => {
    const destination = rootGetters['destination/destinationsById'][value];
    return { text: `Destination: ${destination.name}`, value };
  },
  driverId: ({ value, rootGetters }) => {
    const driver = rootGetters['driver/driversById'][value];
    return { text: `Driver: ${driver.firstName} ${driver.lastName}`, value };
  },
  driverDesignation: ({ value }) => ({ text: `Driver Designation: ${value}`, value }),
  driverHoursEntered: 'Driver Hours Entered',
  fundingSourceId: ({ value, rootGetters }) => {
    const fundingSource = rootGetters['fundingSource/fundingSourcesById'][value];
    return { text: `Funding Source: ${fundingSource.name}`, value };
  },
  leaveDate: ({ value }) => {
    let text = 'Today';
    if (Array.isArray(value)) {
      text = `Date Range: ${value.join(' to ')}`;
    }
    return { text, value };
  },
  leaveDateOnward: 'Upcoming',
  locationId: ({ value, rootGetters }) => {
    const location = rootGetters['location/locationsById'][value];
    return { text: `Location: ${location.name}`, value };
  },
  mileageEntered: 'Mileage Entered',
  needsDriver: 'Needs Driver',
  needsVehicle: 'Neds Vehicle',
  status: ({ state, value }) => {
    const stateStatusCfg = state.statusConfig[value];
    return { text: stateStatusCfg?.text || value, value };
  },
  tripRequestId: ({ value }) => {
    return { text: `Trip #: ${value.join(', ')}`, value };
  },
  tripEventId: ({ value, rootGetters }) => {
    const tripType = rootGetters['tripEvent/tripEventsById'][value];
    return { text: `Trip Event: ${tripType.name}`, value };
  },
  tripTypeId: ({ value, rootGetters }) => {
    const tripType = rootGetters['tripType/tripTypesById'][value];
    return { text: `Trip Type: ${tripType.name}`, value };
  },
  vehicleId: ({ value, rootGetters }) => {
    const vehicle = rootGetters['vehicle/vehiclesById'][value];
    return { text: `Vehicle: ${vehicle.name}`, value };
  },
  vehicleOwner: ({ value, rootGetters }) => {
    const vehicleOwner = rootGetters['user/vehicleOwnerByUserId'][value];
    return { text: `Vehicle Owner: ${vehicleOwner.displayName}`, value: vehicleOwner.userId };
  },
  vehicleTypeId: ({ value, rootGetters }) => {
    const vehicleType = rootGetters['vehicleType/vehicleTypesById'][value];
    return { text: `Vehicle Type: ${vehicleType.name}`, value };
  },
  zone: ({ value }) => ({ text: `Zone: ${value}`, value }),
  displaySpecialInvoice: 'Display Special Invoice',
};

const getAllowedStatuses = (state, me) => {
  if (
    me.is.finance ||
    me.is.limitedAdmin ||
    me.is.siteAdmin ||
    me.is.siteAdminReadOnly ||
    me.is.siteAdminReportsOnly ||
    me.is.superAdmin ||
    me.is.transportationAdmin
  ) {
    return ['approved', 'sent', 'paid', 'pending', 'do_not_bill'];
  } else if (me.is.schoolFinance) {
    return ['sent'];
  } else if (me.is.fundingManager) {
    return ['sent'];
  }

  return [];
};

const getExcludedStatuses = (status) => {
  // exclude for non-approved invoice

  return [];
};

export const getters = {
  invoiceColumns(state) {
    const columns = cloneDeep(state.columns);
    const statusHeader = columns.find((header) => header.text === 'Status');

    if (statusHeader) {
      statusHeader.render = (item) => {
        return state.statusConfig[item.status] || {};
      };
    }

    return columns;
  },
  invoiceFundingColumns(state) {
    const columns = cloneDeep(state.columns);
    const invoicePaymentsFunding = columns.find((header) => header.text === 'Funding');
    const invoicePaymentsCheckNumber = columns.find((header) => header.text === 'Check #');
    const invoicePaymentsBatchJournalNumber = columns.find((header) => header.text === 'Batch/Journal #');

    if (invoicePaymentsFunding)
      invoicePaymentsFunding.render = (item) => item.invoicePayments[0]?.fundingSource?.name ?? '';
    if (invoicePaymentsCheckNumber)
      invoicePaymentsCheckNumber.render = (item) => item.invoicePayments[0]?.checkNumber ?? '';
    if (invoicePaymentsBatchJournalNumber)
      invoicePaymentsBatchJournalNumber.render = (item) => item.invoicePayments[0]?.batchJournalNumber ?? '';
  },
  statusConfig(state) {
    return state.statusConfig;
  },
  statusOptions(state) {
    return Object.keys(state.statusConfig).map((key) => ({
      text: state.statusConfig[key].text,
      value: key,
    }));
  },
  getFilteredInvoiceStatusOptions(state, _, rootState) {
    return (status) => {
      // check roles first
      const allowedStatuses = getAllowedStatuses(state, rootState.user.me);
      const excludedStatus = getExcludedStatuses(status);

      return Object.keys(state.statusConfig)
        .filter((key) => {
          const isCurrentStatus = key === status;
          const isAllowed = allowedStatuses.includes(key);
          const isNotExcluded = !excludedStatus.includes(key);

          return isCurrentStatus || (isAllowed && isNotExcluded);
        })
        .map((key) => ({
          text: state.statusConfig[key].text,
          value: key,
        }));
    };
  },
  selectedInvoice(state) {
    return state.selectedInvoice;
  },
  canUpdateInvoiceStatus(state, getters, rootState) {
    return (currentStatus) => {
      const me = rootState.user.me;

      if (me.is.superAdmin || me.is.transportationAdmin) return true;
      if (me.is.fundingManager || me.is.schoolFinance) return currentStatus === 'approved';

      return !getters.invoiceReadOnly;
    };
  },
  canUpdateInvoicePaymentStatus(state, getters, rootState, rootGetters) {
    return (invoicePayment) => {
      const me = rootState.user.me;

      let allowed = rootGetters['user/canManageInvoice'];

      if (allowed) return true;

      if (me.is.superAdmin || me.is.transportationAdmin) allowed = true;
      if ((me.is.schoolFinance || me.is.fundingManager) && invoicePayment.status === 'approved') allowed = true;
      if (!getters.invoiceReadOnly) allowed = true;

      return allowed && invoicePayment.permission.canUpdate;
    };
  },
  canUpdateInvoiceFundingStatus() {
    return (invoice) => {
      if (invoice.status !== 'pending') return true;
      return false;
    };
  },
  invoices(state) {
    return state.invoices.data;
  },
  invoicesTotal(state) {
    return state.invoices.total;
  },
  invoicePaginationDetails(state) {
    return {
      currentPage: state.searchOptions.page.currentPage,
      total: state.invoices.total,
      searchOptions: state.searchOptions.page,
    };
  },
  invoiceFilters(state) {
    return state.filters;
  },
  invoiceSearchOptions(state) {
    return state.searchOptions;
  },
  invoiceReadOnly(state, _, rootState, rootGetters) {
    if (!state.selectedInvoice) return true;
    if (rootGetters['user/canManageInvoice']) return false; // Editable for full-access users

    if (rootGetters['user/me']?.is?.schoolFinance) return true; // Read-only for school finance

    const payments = state.selectedInvoice?.invoicePayments;

    if (!payments.length) return false; // Editable if no payments are present

    return payments.some((p) => p?.status !== 'pending'); // Read-only if any payment is non-pending
  },
  invoiceIsFetching(state) {
    return state.loading.invoice;
  },
  invoiceArrayFilters(state, getters, rootState, rootGetters) {
    const filters = [];
    const filterText = filterConfig;

    for (const [key, value] of Object.entries(state.filters)) {
      const filterValue = filterText[key];
      if (multipleFieldFilters.has(key) && Array.isArray(value)) {
        filters.push(
          ...value.map((v) => ({
            ...filterValue({ state, value: v, getters, rootState, rootGetters }),
            field: key,
            multiple: true,
          }))
        );
      } else if (typeof filterValue === 'function') {
        filters.push({ ...filterValue({ state, value, getters, rootState, rootGetters }), field: key });
      } else if (filterValue) {
        filters.push({ text: filterValue, value: key, field: key });
      }
    }

    return filters.filter((item) => item);
  },
  isInvoiceFilterDisabled(state) {
    return state.isFilterDisabled;
  },
  invoiceSortOptions(state) {
    return state.sortOptions;
  },
  invoiceCurrentSort(state) {
    return state.searchOptions.sort;
  },
  invoiceDetailsStaffCost(state) {
    return state.details.staffCost;
  },
  invoiceDetailsTravelCost(state) {
    return state.details.travelCost;
  },
  invoiceDetailsAdditionalCharge(state) {
    return state.details.additionalCharge;
  },
  invoiceDetailsFunding(state) {
    return state.details.funding;
  },
  invoiceDetailsAttachments(state) {
    return state.details.attachments;
  },
  invoiceDetailsPayment(state) {
    return state.details.payment;
  },
  invoiceDetailsHistory(state) {
    return state.details.history;
  },
  invoiceIsFetchingStaffCost(state) {
    return state.loading.staffCost;
  },
  invoiceIsFetchingTravelCost(state) {
    return state.loading.travelCost;
  },
  invoiceIsFetchingAdditionalCharge(state) {
    return state.loading.additionalCharge;
  },
  invoiceIsFetchingPayment(state) {
    return state.loading.payment;
  },
  invoiceStaffBenefits(state) {
    const invoiceDetailsStaffCost = state.details.staffCost;
    const retirementConfigMap = {
      driver: invoiceDetailsStaffCost?.driverRetirement === 1,
      assistant: invoiceDetailsStaffCost?.assistantRetirement === 1,
    };
    if (!invoiceDetailsStaffCost || !invoiceDetailsStaffCost.invoiceStaffs)
      return {
        driver: {
          retirement: 0,
          ss: 0,
          workerComp: 0,
          medical: 0,
        },
        assistant: {
          retirement: 0,
          ss: 0,
          workerComp: 0,
          medical: 0,
        },
      };
    else if (invoiceDetailsStaffCost.status !== 'pending')
      return {
        driver: {
          retirement: +invoiceDetailsStaffCost.invoiceDriverBenefit?.benefitRetirement || 0,
          ss: +invoiceDetailsStaffCost.invoiceDriverBenefit?.benefitSS || 0,
          workerComp: +invoiceDetailsStaffCost.invoiceDriverBenefit?.benefitWorkerComp || 0,
          medical: +invoiceDetailsStaffCost.invoiceDriverBenefit?.benefitMedical || 0,
        },
        assistant: {
          retirement: +invoiceDetailsStaffCost.invoiceAssistantBenefit?.benefitRetirement || 0,
          ss: +invoiceDetailsStaffCost.invoiceAssistantBenefit?.benefitSS || 0,
          workerComp: +invoiceDetailsStaffCost.invoiceAssistantBenefit?.benefitWorkerComp || 0,
          medical: +invoiceDetailsStaffCost.invoiceAssistantBenefit?.benefitMedical || 0,
        },
      };

    return computeStaffBenefits(invoiceDetailsStaffCost, invoiceDetailsStaffCost.invoiceStaffs, retirementConfigMap);
  },
  invoiceTotalCosts(state, getters) {
    const costs = {
      staff: 0,
      travel: 0,
      additional: state.selectedInvoice?.additionalCost,
      total: 0,
    };

    if (state.details.staffCost) {
      const { driver, assistant } = getters.invoiceStaffBenefits;
      costs.staff =
        state.details.staffCost.invoiceStaffs.reduce((accumulator, currentValue) => {
          return accumulator + Number(currentValue.total);
        }, 0) +
        driver.retirement +
        driver.ss +
        driver.workerComp +
        driver.medical +
        assistant.retirement +
        assistant.ss +
        assistant.workerComp +
        assistant.medical;
    }

    if (state.details.travelCost) {
      costs.travel = state.details.travelCost.invoiceTravel.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.total);
      }, 0);
    }

    if (state.details.additionalCharge) {
      costs.additional = state.details.additionalCharge.invoiceAdditionalCharge.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.amount);
      }, 0);
    }

    const total = Number(costs.staff) + Number(costs.travel) + Number(costs.additional);

    costs.total = Number(total || 0).toFixed(2);
    costs.staff = Number(costs.staff || 0).toFixed(2);
    costs.travel = Number(costs.travel || 0).toFixed(2);
    costs.additional = Number(costs.additional || 0).toFixed(2);

    return costs;
  },
  invoiceIsFetchingHistory(state) {
    return state.loading.history;
  },
  invoiceHiddenColumns(state) {
    return state.columns.filter((column) => !column.show);
  },
};
