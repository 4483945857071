var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.password.length
    ? _c(
        "v-card",
        { staticClass: "mt-4 mb-6" },
        [
          _c(
            "v-card-text",
            [
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    {
                      class:
                        _vm.strength.length >= 8 ? "green--text" : "red--text",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.strength.length >= 8
                              ? "mdi-close-thick"
                              : "mdi-check-bold"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm._v(" Length: "),
                  _c("span", [_vm._v(_vm._s(_vm.strength.length))]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    {
                      class: _vm.strength.hasUpper
                        ? "green--text"
                        : "red--text",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.strength.hasUpper
                              ? "mdi-close-thick"
                              : "mdi-check-bold"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm._v(" Uppercase letter "),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    {
                      class: _vm.strength.hasLower
                        ? "green--text"
                        : "red--text",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.strength.hasLower
                              ? "mdi-close-thick"
                              : "mdi-check-bold"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm._v(" Lowercase letter "),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    {
                      class: _vm.strength.hasNum ? "green--text" : "red--text",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.strength.hasNum
                              ? "mdi-close-thick"
                              : "mdi-check-bold"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm._v(" Number "),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    {
                      class: _vm.strength.hasSpecial
                        ? "green--text"
                        : "red--text",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.strength.hasSpecial
                              ? "mdi-close-thick"
                              : "mdi-check-bold"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm._v(" Symbols (e.g. ~!@#$%^&) "),
                ],
                1
              ),
              _c("div", [
                _vm._v(" Strength: "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.strength.score == 0,
                        expression: "strength.score == 0",
                      },
                    ],
                    staticClass: "font-weight-bold red--text",
                  },
                  [_vm._v("Very Low")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.strength.score == 1,
                        expression: "strength.score == 1",
                      },
                    ],
                    staticClass: "font-weight-bold red--text",
                  },
                  [_vm._v("Low")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.strength.score == 2,
                        expression: "strength.score == 2",
                      },
                    ],
                    staticClass: "font-weight-bold orange--text",
                  },
                  [_vm._v("Medium")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.strength.score == 3,
                        expression: "strength.score == 3",
                      },
                    ],
                    staticClass: "font-weight-bold green--text",
                  },
                  [_vm._v("High")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.strength.score == 4,
                        expression: "strength.score == 4",
                      },
                    ],
                    staticClass: "font-weight-bold green--text",
                  },
                  [_vm._v("Very High")]
                ),
              ]),
              _c("v-progress-linear", {
                attrs: { value: (_vm.strength.guesses / 12) * 100 },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }