var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar-items",
    { staticClass: "w-full" },
    [
      _c("sub-menu", {
        attrs: {
          name: "Setup Tables Menu",
          "menu-items": _vm.pages,
          showDropDown: "",
        },
        on: { "sub-menu-click": _vm.onMenuItemClick },
      }),
      _vm.showSLAManager
        ? _c(
            "v-btn",
            {
              staticClass: "nav-button text-capitalize white--text",
              attrs: { color: "primary", elevation: "0" },
              on: { click: _vm.manageLocationSLA },
            },
            [_vm._v(" Manage Site Level Authority ")]
          )
        : _vm._e(),
      _vm.currentPage == "destinations"
        ? _c(
            "v-btn",
            {
              staticClass: "nav-button text-capitalize white--text",
              attrs: { color: "primary", elevation: "0" },
              on: { click: _vm.showProspectiveDestinations },
            },
            [
              _vm._v(
                " Show " +
                  _vm._s(_vm.prospective ? "" : "Prospective") +
                  " Destinations "
              ),
            ]
          )
        : _vm._e(),
      _vm.currentPage === "drivers" && _vm.canZeroOutDrivers
        ? _c(
            "v-btn",
            {
              staticClass: "nav-button text-capitalize white--text",
              attrs: { color: "primary", elevation: "0" },
              on: { click: _vm.zeroOut },
            },
            [_vm._v(" Zero Out Drivers ")]
          )
        : _vm._e(),
      _c("v-spacer"),
      _vm.showHidden
        ? _c(
            "v-btn",
            {
              staticClass: "nav-button text-capitalize white--text",
              attrs: { color: "primary", elevation: "0" },
              on: { click: _vm.showHiddenValues },
            },
            [
              _vm._v(
                " Show " +
                  _vm._s(_vm.hidden ? "" : "Hidden") +
                  " " +
                  _vm._s(_vm.menuLabel) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }