var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        timeout: 3500,
        color: _vm.colors[_vm.type],
        absolute: "",
        top: "",
      },
      on: { input: _vm.handleInput },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _vm.buttonLabel
                ? _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "#fff", outlined: "", text: "" },
                        on: { click: _vm.handleButton },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "#fff", small: "", fab: "", text: "" },
                  on: { click: _vm.close },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.alert,
        callback: function ($$v) {
          _vm.alert = $$v
        },
        expression: "alert",
      },
    },
    [
      _c("v-icon", { attrs: { color: "#FFF" } }, [
        _vm._v(_vm._s(_vm.icons[_vm.type])),
      ]),
      _c("div", { staticClass: "snackbar-message-wrapper" }, [
        _c("div", [_vm._v(_vm._s(_vm.message))]),
        _vm.messageArray.length
          ? _c(
              "div",
              _vm._l(_vm.messageArray, function (message) {
                return _c("p", { key: message, staticClass: "mb-1" }, [
                  _vm._v(" " + _vm._s(message) + " "),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm.subMessage
          ? _c("div", { staticClass: "snack-sub-message" }, [
              _vm._v(_vm._s(_vm.subMessage)),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }