export default {
  getVehicles,
  getAvailableVehicles,
  saveVehicle,
  deleteVehicles,
};

////////////

import api from '@/shared/api.service';

async function getVehicles(options = {}) {
  return await api.get('/api/vehicle', { params: options });
}

async function getAvailableVehicles(params) {
  let str = `/api/vehicle/available?fromDate=${params.fromDate}&fromTime=${params.fromTime}&toDate=${params.toDate}&toTime=${params.toTime}&type=${params.type}`;
  if (params.override) str += `&override=${params.override}`;
  return await api.get(str);
}

async function saveVehicle(vehicle) {
  return await api.post('/api/vehicle', vehicle);
}

async function deleteVehicles(ids) {
  return await api.delete('/api/vehicle', {
    params: { ids },
  });
}
