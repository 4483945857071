import Vue from 'vue';
import Vuex from 'vuex';

import { actions as UserActions, CHECK_PASSWORD } from '@/store/modules/User/actions';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    user: {
      namespaced: true,
      actions: {
        [CHECK_PASSWORD]: UserActions[CHECK_PASSWORD],
      },
    },
  },
  strict: debug,
});
