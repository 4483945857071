import store from '@/store';
import { format } from 'date-fns';
import { ROLES, TRIP_TYPES } from '@/shared/common';
import { TRIP_STATUS } from '../shared/common';

export const addBudgetCodeStrings = (budgetCodes) => {
  if (!budgetCodes || !budgetCodes.length) return [];
  for (let bc of budgetCodes) {
    bc.codeStr = `${bc.id} ${bc.name}`;
    bc.codeLabel = `${bc.id} (${bc.name})`;
  }

  return budgetCodes;
};

export const fillInBudgetCodes = (budgetCodes, locationCode) => {
  if (typeof budgetCodes === 'string') {
    return budgetCodes.replace(/\?\?\?/g, locationCode);
  } else {
    for (let bc of budgetCodes) {
      const codes = Object.values(bc.code);
      if (codes?.length > 0) bc.description = codes.join(' ');
      else bc.description = bc.name;

      if (bc.codeStr.indexOf('?') != -1) {
        bc.codeStr = bc.codeStr.replace(/\?\?\?/g, locationCode);
        bc.codeLabel = bc.codeLabel.replace(/\?\?\?/g, locationCode);
      } else bc.codeStr = bc.description;
    }
    return budgetCodes;
  }
};

export const getVehicleLocation = (vehicle, returnObj) => {
  if (!vehicle) return;

  const locations = store.getters['location/locations'];
  const vehiclesById = store.getters['vehicle/vehiclesById'];

  let vehicleId;
  if (!Number.isInteger(vehicle) || !vehicle.id) {
    vehiclesById[vehicle.id] = vehicle;
    vehicleId = vehicle.id;
  } else vehicleId = vehicle;

  if (!vehiclesById[vehicleId]?.depotId) return;
  const vl = locations.find((e) => e.depotId == vehiclesById[vehicleId].depotId);
  if (!vl || returnObj) return vl;
  return vl.name;
};

export const getVehicleOwner = (trip) => {
  const roleAssignments = store.getters['user/roleAssignments'];
  const users = store.getters['user/users'];
  const vehicleOwner = roleAssignments.find(
    (e) => e.roleId == ROLES.VEHICLE_OWNER && e.locationIds.includes(trip.assignmentLocationId)
  );
  const specialNeedsVehicleOwner = roleAssignments.find(
    (e) => e.roleId == ROLES.SPECIAL_NEEDS_VEHICLE_OWNER && e.locationIds.includes(trip.assignmentLocationId)
  );
  const owner = trip.tripTypeId == TRIP_TYPES.SPECIAL_NEEDS ? specialNeedsVehicleOwner : vehicleOwner;
  if (!owner) return null;
  const ownerUser = users.find((e) => e.email == owner.userEmail);
  if (ownerUser) return ownerUser;
  return { email: owner.userEmail, displayName: 'Unregistered User' };
};

export const getAssignmentVehicleType = (assignment) => {
  if (!assignment) return;
  const vehiclesById = store.getters['vehicle/vehiclesById'];
  const vehicleTypesById = store.getters['vehicleType/vehicleTypesById'];

  if (assignment.vehicleId && vehiclesById[assignment.vehicleId])
    return vehicleTypesById[vehiclesById[assignment.vehicleId].type];
  return vehicleTypesById[assignment.vehicleTypeId];
};

export const handleErrors = ($v, compareField = '') => {
  const errors = [];

  if (!$v.$dirty) return errors;

  if (Object.prototype.hasOwnProperty.call($v, 'sameAs') && !$v.sameAs)
    errors.push(`Must be the same as ${compareField}`);
  if (Object.prototype.hasOwnProperty.call($v, 'strength') && !$v.strength) errors.push('Password is too weak');
  if (Object.prototype.hasOwnProperty.call($v, 'email') && !$v.email) errors.push('Enter a valid email address');
  if (Object.prototype.hasOwnProperty.call($v, 'required') && !$v.required) errors.push('Required');
  if (Object.prototype.hasOwnProperty.call($v, 'numeric') && !$v.numeric) errors.push('Numeric values only');

  if (Object.prototype.hasOwnProperty.call($v, 'maxLength') && !$v.maxLength) {
    if ($v.$params.minLength && $v.$params.minLength.min === $v.$params.maxLength.max)
      errors.push(`Must be exactly ${$v.$params.maxLength.max} characters`);
    else errors.push($v.$params.maxLength.max + ' characters max');
  }

  if (Object.prototype.hasOwnProperty.call($v, 'minLength') && !$v.minLength) {
    if ($v.$params.maxLength && $v.$params.maxLength.max === $v.$params.minLength.min)
      errors.push(`Must be exactly ${$v.$params.minLength.min} characters`);
    else errors.push(`Must be at least ${$v.$params.minLength.min} characters long`);
  }

  if (Object.prototype.hasOwnProperty.call($v, 'between') && !$v.between) {
    errors.push(`Ranges only from ${$v.$params.between.min} to ${$v.$params.between.max}`);
  }

  if (Object.prototype.hasOwnProperty.call($v, 'minValue') && !$v.minValue) {
    errors.push(`Minimum value is ${$v.$params.minValue.min}`);
  }

  return errors;
};

export const isValidEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const randomString = (length) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567890123456789';

  for (let i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

// YYYY-MM-DD => MM/DD/YYYY
export const readableDate = (date) => {
  if (!date) return '';
  const split = date.split('-');
  return `${split[1]}/${split[2]}/${split[0]}`;
};

export const readableTime = (time) => {
  if (!time) return '';
  const s = time.split(':');
  const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
  const minute = s[1];
  const ampm = s[0] >= 12 ? 'pm' : 'am';
  return `${hour}:${minute} ${ampm}`;
};

export const todayString = () => {
  const t = new Date();
  const y = t.getFullYear();
  const m = (t.getMonth() + 1).toString().length > 1 ? t.getMonth() + 1 : `0${t.getMonth() + 1}`;
  const d = t.getDate().toString().length > 1 ? t.getDate() : `0${t.getDate()}`;
  return `${y}-${m}-${d}`;
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const toLowerCamelCase = (str) => {
  return str
    .replace(/\s(.)/g, (e) => {
      return e.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, (e) => {
      return e.toLowerCase();
    });
};

export const toMoney = (num, fixedDec, noSign) => {
  if (!num || isNaN(num) || num === Infinity) return `${noSign ? '' : '$'}0`;
  const whole = Math.floor(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, noSign ? '' : ',');
  const dec = String((num % 1).toFixed(fixedDec || 2)).split('.')[1];
  return `${noSign ? '' : '$'}${whole}${dec !== '00' ? `.${dec}` : ''}`;
};

export const getMiles = (meters) => {
  return Math.round((meters * 0.000621371192 + Number.EPSILON) * 100) / 100;
};

export const getMilesForDirections = (meters) => {
  const d = Math.round((meters * 0.000621371192 + Number.EPSILON) * 10) / 10;
  if (d < 0.2) return Math.round(meters * 3.28084) + ' ft';
  return d + ' mi';
};

export const getTime = (millis) => {
  const mins = Math.floor(millis / 60000);
  return hoursAndMinutes(mins);
};

export const hoursAndMinutes = (mins) => {
  return Math.floor(mins / 60) > 0 ? `${Math.floor(mins / 60)}h ${mins % 60}m` : mins + ' mins';
};

export const convertGoogleAddress = (googleAddress) => {
  const streetNumber = googleAddress.address_components.find((e) => e.types.includes('street_number'));
  const subpremise = googleAddress.address_components.find((e) => e.types.includes('subpremise'));
  const route = googleAddress.address_components.find((e) => e.types.includes('route'));
  const locality = googleAddress.address_components.find((e) => e.types.includes('locality'));
  const state = googleAddress.address_components.find((e) => e.types.includes('administrative_area_level_1'));
  const postalCode = googleAddress.address_components.find((e) => e.types.includes('postal_code'));

  return {
    name: googleAddress.name || `${streetNumber.short_name} ${route.short_name}`,
    address: `${streetNumber ? streetNumber.short_name + ' ' : ''}${route ? route.short_name : ''}`,
    address2: subpremise ? subpremise.short_name : '',
    city: locality ? locality.short_name : '',
    state: state ? state.short_name : '',
    zip: postalCode ? postalCode.short_name : '',
  };
};
/**
 * Calculates the time shift to be used when updating the calendar focus.
 * The shift depends on the current calendar view type.
 * @param {string} calendarType - The type of calendar view (day, week, month, or 4day).
 * @returns {Object} An object containing the time shift, in terms of months, weeks, or days.
 */
export const calculateDateShift = (calendarType) => {
  // Set the type to the given calendar type or to the default type if none is given
  const type = calendarType || 'default';
  // Use a switch statement to return the appropriate time shift based on the type
  switch (type) {
    case 'month':
      return { months: 1 };
    case 'week':
      return { weeks: 1 };
    case 'day':
      return { days: 1 };
    case '4day':
      return { days: 4 };
    default:
      return { months: 1 };
  }
};

/**
 * Creates a new Date object representing the given date adjusted by the local timezone offset
 * @param {string} date
 * @returns {datetime}
 */
export const dateWithOffset = (date) => {
  if (!date) {
    return '';
  }
  const d = new Date(date);
  const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
  return dt;
};

/**
 * Default properties for input components
 * @type {Object.<string, any>}
 */
export const inputProps = {
  outlined: true,
  hideDetails: 'auto',
};

/**
 * Returns the plural form of a word based on the provided value.
 *
 * @param {string} word - The word to be potentially pluralized.
 * @param {number} value - The value determining whether to pluralize the word.
 * @returns {string} - The original word or its plural form depending on the value.
 */
export const pluralize = (word, value) => {
  if (value !== 1) {
    return `${word}s`;
  }
  return word;
};

/**
 * Colors
 * @type {Object.<string, string>}
 */
export const colors = {
  green: '#7AC755',
  lightGreen: '#DEF3D4',
  blue: '#4066A1',
  lightBlue: '#E4EFFB',
  lightPink: '#EFD4E1',
  lightRed: '#F78E1E33',
};

/**
 * Adds leading zero to time
 * @type {Object.<string, string>}
 */
export const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  const formattedHours = hours.padStart(2, '0');
  return `${formattedHours}:${minutes}`;
};

// localStorage setter
export function setLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

// localStorage getter
export function getLocalStorage(key) {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}
/**
 *  Humanize file size
 * @param {number} bytes - File size in bytes
 * @param {boolean} si - Use SI units
 * @param {number} dp - Decimal places
 *
 * @returns {string} - Humanized file size
 */
export const humanizeFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

/**
 * @param {Date|Datetime|String} date
 */
export const toDateString = (date) => {
  let dt;
  if (typeof date === 'string') {
    const dateString = date.split('T')[0];
    dt = new Date(dateString);
  } else if (dt instanceof Date) {
    // no need to do anything
  } else {
    dt = new Date(date);
  }
  dt = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

  return format(dt, 'LLL dd yy');
};

/**
 * @param {Date|String} date
 * @param {String} time
 */
export const toDateTimeString = (date, time) => {
  let dt = new Date(date);

  // If no time is provided, set default time to 8:00 AM
  if (!time) {
    dt.setHours(8);
    dt.setMinutes(0);
  } else {
    const [hours, minutes] = time.split(':').map(Number);
    if (!isNaN(hours) && !isNaN(minutes)) {
      dt.setHours(hours);
      dt.setMinutes(minutes);
    }
  }

  return format(dt, 'LLL dd yyyy h:mm a');
};

/**
 * @param {String} time
 */
export const toTimeString = (time) => {
  if (!time) return null;

  const [rawHours, minutes] = time.split(':');
  let hours, amOrPm;

  // special case for 12 am
  if (+rawHours === 0) {
    hours = 12;
    amOrPm = 'am';
  } else if (rawHours > 12) {
    amOrPm = 'pm';
    hours = rawHours % 12;
  } else {
    amOrPm = 'am';
    hours = +rawHours;
  }

  return `${hours}:${minutes} ${amOrPm}`;
};

/**
 * @param {Date|Datetime|String} date
 */
export const toLocaleDateTime = (date) => {
  if (!date) return '';

  return new Date(date).toLocaleString();
};

/**
 * @param {String} str
 */
export const toUcWords = (str) => {
  return str
    ?.split(' ')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');
};

/**
 * Converts a number to a string formatted with commas for thousands and exactly two decimal places.
 *
 * @param {number} number - The number to be formatted.
 * @returns {string} The formatted number as a string with commas and two decimal places.
 */
export const toNumberString = (number) => {
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

/**
 * Converts a number to a dollar amount, e.g. 3412 -> $3,412.
 *
 * @param {number} number - The number to be formatted.
 * @returns {string} The formatted number as a string with commas and two decimal places.
 */
export const toDollarAmount = (number) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return USDollar.format(number);
};

/**
 * Flag that can restricts certain features depending on an environment variable
 */
export const showDevFeatures = () => {
  return process.env.VUE_APP_SHOW_DEV_FEATURES == 'true';
};

export const SINGLE_BUDGET_CODE_IDS = [1, 2];

export const checkTripReadOnlyByPermissions = ({ permissions = {}, isAdmin = false, isApproved = false }) => {
  if (isAdmin) return false;

  const currentTripRequest = store.getters['tripRequest/currentTripRequest'];
  if ([TRIP_STATUS.DRAFT, TRIP_STATUS.RESUBMIT].includes(currentTripRequest?.status)) return false;

  if (isApproved) return true;
  if (permissions.canEditSiteAdmin) return false;
  if (permissions.canEditPreApproval) return false;
  if (permissions.canEditMidApproval) return false;
  if (permissions.canEditApproved) return false;
  if (permissions.canEdit) return false;

  return true;
};

export const getBudgetCodeDescriptionWithLocation = ({
  fundingSource,
  locationCode,
  budgetCodeConfig,
  budgetCodes,
}) => {
  const defaultDescription = fundingSource.budgetCode || fundingSource.budgetCodeDescription;

  const index = budgetCodeConfig.findIndex((config) => config.locationDependent === true);

  if (index === -1) return defaultDescription;

  const structure = fundingSource.budgetCodeStructure?.split(',');

  if (!structure?.[index]) return defaultDescription;

  structure[index] = locationCode;

  const budgetCode = budgetCodes?.find((code) => code.id === fundingSource.budgetCodeId);

  if (!budgetCode) return defaultDescription;

  const budgetCodeValues = Object.values(budgetCode.code);

  if (!budgetCodeValues[index].includes('?')) return defaultDescription;

  if (structure?.length > 0) return structure.join(' ');
  else return budgetCode.name;
};

export const isFundingSourceNoBudgetCode = (fundingSourceIndex) => {
  const currentTripRequest = store.getters['tripRequest/currentTripRequest'];

  if (!currentTripRequest) return false;

  const fundingSource = currentTripRequest.fundingSources[fundingSourceIndex];
  const fundingSourcesById = store.getters['fundingSource/fundingSourcesById'];

  if (!currentTripRequest) return false;

  if (
    !fundingSource.budgetCodeId &&
    !fundingSource.budgetCode &&
    fundingSourcesById[fundingSource.fundingSourceId].type !== 4
  )
    return true;

  return false;
};

/**
 * Converts a snake case string to normal case.
 *
 */
export const convertSnakeCaseToNormalCase = (snakeCaseString) => {
  if (!snakeCaseString) return '';
  return snakeCaseString
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const percentageValue = ({ amount, total }) => {
  if (!amount) return 0;

  const numericAmount = Number(amount);
  const numericTotal = Number(total);

  if (isNaN(numericAmount) || isNaN(numericTotal)) return 0;

  return Number((numericTotal * (numericAmount / 100)).toFixed(2));
};

export const tripScheduleDetails = ({ leg = 0, trip = {} }) => {
  let fromDate = trip.leaveDate;
  let fromTime = trip.leaveTime;
  let toDate = trip.returnDate;
  let toTime = trip.returnTime;

  if (leg === 1) {
    fromDate = trip.dropOffLegVehPickupDate;
    fromTime = trip.dropOffLegVehPickupTime;
    toDate = trip.dropOffLegVehReturnDate;
    toTime = trip.dropOffLegVehReturnTime;
  } else if (leg === 2) {
    fromDate = trip.returnLegVehPickupDate;
    fromTime = trip.returnLegVehPickupTime;
    toDate = trip.returnLegVehReturnDate;
    toTime = trip.returnLegVehReturnTime;
  }

  return {
    isSplitTrip: leg > 0,
    fromDate,
    fromTime,
    toDate,
    toTime,
  };
};

export const getAuditDescription = (description) => {
  const parts = description.split(';');
  const approvalLevelDescription = parts.find((part) => part.trim().startsWith('Approval Level ID:'));
  const descriptionParts = parts.slice(0, 2);

  if (approvalLevelDescription) {
    const approvalLevelId = approvalLevelDescription.match(/\d+$/);
    const approvalLevel = store.getters['approvalLevel/approvalLevelsById']?.[approvalLevelId];

    if (approvalLevel?.name) descriptionParts.push(approvalLevel.name);
  }

  return descriptionParts.filter(Boolean).join(' - ');
};

export const getBusinessName = (location) => {
  return location?.structured_formatting?.main_text || location?.name || '';
};
