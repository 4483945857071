var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "mainLayout" },
    [
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 5000, top: "", right: "", "multi-line": "" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "red", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.closeSnackbar()
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Close ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showSnackbar,
            callback: function ($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-column align-center mt-10" },
        [
          _c("TravelTrackerLogo", { staticClass: "login-image my-10" }),
          _c(
            "v-card",
            { staticClass: "card" },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-5" },
                [
                  _c("v-row", { staticClass: "justify-center mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column justify-center" },
                      [
                        _c(
                          "h4",
                          {
                            staticClass:
                              "text-h5 text-center font-weight-bold my-5",
                          },
                          [_vm._v(_vm._s(_vm.pageText.SIGN_IN))]
                        ),
                      ]
                    ),
                  ]),
                  _vm.showEmailField
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column justify-center pt-3",
                        },
                        [
                          _c("v-text-field", {
                            ref: "email",
                            attrs: {
                              label: "Email",
                              required: "",
                              outlined: "",
                              "prepend-inner-icon": "mdi-account",
                              disabled: _vm.disableField,
                              "error-messages": _vm.errorText,
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.submit()
                              },
                            },
                            model: {
                              value: _vm.username,
                              callback: function ($$v) {
                                _vm.username = $$v
                              },
                              expression: "username",
                            },
                          }),
                          _vm.showGDICPasswordField
                            ? _c("v-text-field", {
                                ref: "password",
                                attrs: {
                                  type: "password",
                                  label: "Password",
                                  required: "",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-lock",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.submit()
                                  },
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "btn-flat waves-effect waves-light login-button",
                              attrs: {
                                type: "button",
                                large: "",
                                ripple: "",
                                disabled: _vm.disableButton,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submit()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
                          ),
                          _c("div", { staticClass: "mt-10" }, [
                            _c(
                              "p",
                              { staticClass: "text-subtitle-2 text-center" },
                              [
                                _vm._v(
                                  "© 2023 - " +
                                    _vm._s(_vm.currentYear) +
                                    " TransAct Communications, LLC."
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center my-5" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-decoration-none",
                                    attrs: {
                                      href: "https://transact.com/privacy-policy",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-link-variant"),
                                    ]),
                                    _vm._v(" Privacy Policy"),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "mx-3" }, [
                                  _vm._v("|"),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-decoration-none",
                                    attrs: {
                                      href: "https://transact.com/terms-of-use",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-link-variant"),
                                    ]),
                                    _vm._v(" Terms of Use"),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showLoginOptions
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column align-center mb-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "my-4",
                              attrs: { color: "secondary", plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.displayEmailField()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v(" mdi-arrow-left "),
                              ]),
                              _vm._v("Go Back to Login"),
                            ],
                            1
                          ),
                          _vm._l(_vm.loginOptions, function (option, count) {
                            return _c(
                              "v-btn",
                              {
                                key: count,
                                staticClass: "mb-3",
                                attrs: {
                                  elevation: "3",
                                  large: "",
                                  color: "secondary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.verifyApplication(option)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { staticClass: "mr-3" }, [
                                  _vm._v(
                                    "mdi-" +
                                      _vm._s(option.provider.toLowerCase())
                                  ),
                                ]),
                                _vm._v(
                                  " Sign in with " +
                                    _vm._s(option.provider) +
                                    " "
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }