import assignmentApi from '@/apis/assignment';

export const GET_ASSIGNMENT = 'getAssignmentById';
export const GET_ASSIGNMENTS = 'getAssignments';
export const GET_MY_ASSIGNMENTS = 'getMyAssignments';
export const SAVE_ASSIGNMENT = 'saveAssignment';
export const BATCH_ASSIGN = 'batchAssign';
export const ACCEPT_ASSIGNMENT = 'acceptAssignment';
export const DELETE_ASSIGNMENT = 'deleteAssignment';
export const SEND_DRIVER_NOTIFICATION = 'sendDriverNotification';
export const PRINT_ASSIGNMENTS = 'printAssignments';
export const EXPORT_ASSIGNMENTS = 'exportAssignments';

export const actions = {
  async [GET_ASSIGNMENT](_, id) {
    return await assignmentApi.getAssignmentById(id);
  },
  async [GET_ASSIGNMENTS]({ commit }, options) {
    commit('isLoadingAssignments', true);
    const list = await assignmentApi.getAssignments(options);
    commit('isLoadingAssignments', false);
    if (options && options.fromDate && options.fromTime && options.toDate && options.toTime) return list;
    else commit('setAssignments', list);
  },
  async [GET_MY_ASSIGNMENTS]() {
    return await assignmentApi.getAssignmentsByUserId();
  },
  async [SAVE_ASSIGNMENT]({ commit }, assignment) {
    const r = await assignmentApi.saveAssignment(assignment);
    commit('updateAssignment', assignment);
    return r;
  },
  async saveAssignmentVehicleNotes({ commit }, obj) {
    const r = await assignmentApi.saveAssignmentVehicleNotes(obj);
    commit('updateAssignment', obj);
    return r;
  },
  async [BATCH_ASSIGN](_, obj) {
    return await assignmentApi.batchAssign(obj);
  },
  async [ACCEPT_ASSIGNMENT](_, obj) {
    return await assignmentApi.acceptAssignment(obj);
  },
  async [DELETE_ASSIGNMENT](_, id) {
    return await assignmentApi.deleteAssignment(id);
  },
  async [SEND_DRIVER_NOTIFICATION](_, params) {
    return await assignmentApi.sendDriverNotification(params);
  },
  async [PRINT_ASSIGNMENTS](_, params) {
    return await assignmentApi.printAssignments(params);
  },
  async [EXPORT_ASSIGNMENTS]({ state }, params) {
    return await assignmentApi.exportAssignments({ ...params, ids: state.filteredAssignmentIds });
  },
  async getFuelCostByAssignmentId(_, assignmentId) {
    return await assignmentApi.computeFuelCostByAssignmentId(assignmentId);
  },
  async updateAssignmentDriverAssistantById(_, params) {
    return await assignmentApi.updateAssignmentDriverAssistantById({ params });
  },
};
