export default {
  getDrivers,
  getDriverById,
  getAvailableDrivers,
  saveDriver,
  zeroOutDrivers,
  deleteDrivers,
};

////////////

import api from '@/shared/api.service';

async function getDrivers(options = {}) {
  return await api.get('/api/driver', { params: options });
}

async function getDriverById(id) {
  return await api.get(`/api/driver/${id}`);
}

async function getAvailableDrivers(params) {
  let str = `/api/driver/available?fromDate=${params.fromDate}&fromTime=${params.fromTime}&toDate=${params.toDate}&toTime=${params.toTime}`;
  if (params.sortMethod) str += `&sortMethod=${params.sortMethod}`;
  if (params.override) str += `&override=${params.override}`;
  return await api.get(str);
}

async function saveDriver(driver) {
  return await api.post('/api/driver', driver);
}

async function zeroOutDrivers() {
  return await api.post('/api/driver/zero-out');
}

async function deleteDrivers(ids) {
  return await api.delete('/api/driver', {
    params: { ids },
  });
}
