import { ADDITIONAL_VEHICLE_TYPES_MAP } from '@/shared/common';

export const getters = {
  vehicleTypes(state, getters, rootState, rootGetters) {
    const additionalTransportations = rootGetters['additionalTransportation/additionalTransportations'];

    const additionalTypesSet = additionalTransportations.reduce((set, transpo) => {
      const mappedType = ADDITIONAL_VEHICLE_TYPES_MAP[transpo.type];
      if (mappedType) set.add(mappedType);

      return set;
    }, new Set());

    return state.vehicleTypes.filter((type) => type.vehicleType === 0 || additionalTypesSet.has(type.vehicleType));
  },
  vehicleTypesById(state) {
    const byId = {};
    state.vehicleTypes.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  vehicleTypesByName(state) {
    const byName = {};
    state.vehicleTypes.forEach((e) => (byName[e.name.toLowerCase()] = e));
    return byName;
  },
};
