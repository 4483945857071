<template>
  <v-card class="mt-4 mb-6" v-if="password.length">
    <v-card-text>
      <div>
        <v-icon :class="strength.length >= 8 ? 'green--text' : 'red--text'">
          {{ strength.length >= 8 ? 'mdi-close-thick' : 'mdi-check-bold' }}
        </v-icon>
        Length:
        <span>{{ strength.length }}</span>
      </div>

      <div>
        <v-icon :class="strength.hasUpper ? 'green--text' : 'red--text'">
          {{ strength.hasUpper ? 'mdi-close-thick' : 'mdi-check-bold' }}
        </v-icon>
        Uppercase letter
      </div>

      <div>
        <v-icon :class="strength.hasLower ? 'green--text' : 'red--text'">
          {{ strength.hasLower ? 'mdi-close-thick' : 'mdi-check-bold' }}
        </v-icon>
        Lowercase letter
      </div>

      <div>
        <v-icon :class="strength.hasNum ? 'green--text' : 'red--text'">
          {{ strength.hasNum ? 'mdi-close-thick' : 'mdi-check-bold' }}
        </v-icon>
        Number
      </div>

      <div>
        <v-icon :class="strength.hasSpecial ? 'green--text' : 'red--text'">
          {{ strength.hasSpecial ? 'mdi-close-thick' : 'mdi-check-bold' }}
        </v-icon>
        Symbols (e.g. ~!@#$%^&)
      </div>

      <div>
        Strength:
        <span class="font-weight-bold red--text" v-show="strength.score == 0">Very Low</span>
        <span class="font-weight-bold red--text" v-show="strength.score == 1">Low</span>
        <span class="font-weight-bold orange--text" v-show="strength.score == 2">Medium</span>
        <span class="font-weight-bold green--text" v-show="strength.score == 3">High</span>
        <span class="font-weight-bold green--text" v-show="strength.score == 4">Very High</span>
      </div>

      <v-progress-linear :value="(strength.guesses / 12) * 100"></v-progress-linear>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

import { CHECK_PASSWORD } from '@/store/modules/User/actions';
import debounceMixin from '@/mixins/debounceMixin';

export default {
  name: 'PasswordStrength',
  mixins: [debounceMixin],
  props: {
    password: {
      default: '',
      require: true,
      type: String,
    },
    value: Number,
  },
  data() {
    return {
      debounceDelay: 200,
      strength: {},
    };
  },
  created() {
    this.checkPasswordDebounced = this.debounce(this.checkPasswordStrength, this.debounceDelay);
  },
  methods: {
    ...mapActions('user', [CHECK_PASSWORD]),
    async checkPasswordStrength(val) {
      if (!val) this.strength = {};

      this.strength = await this[CHECK_PASSWORD]({ newPassword: val });

      this.strength.validated =
        this.strength.length >= 8 &&
        this.strength.hasUpper &&
        this.strength.hasLower &&
        this.strength.hasNum &&
        this.strength.hasSpecial &&
        this.strength.score >= 2;

      this.$emit('input', this.strength.score);
    },
  },
  watch: {
    password(val) {
      this.checkPasswordDebounced(val);
    },
  },
};
</script>

<style></style>
