import { required, minValue, sameAs } from 'vuelidate/lib/validators';

/**
 * Password Form Validations
 * @param {number} minPasswordStrength
 */
const PasswordFormValidations = (minPasswordStrength = 3) => ({
  password: {
    required,
  },
  passwordStrength: {
    strength: minValue(minPasswordStrength),
  },
  passwordConfirm: {
    required,
    sameAs: sameAs('password'),
  },
});

export default PasswordFormValidations;
