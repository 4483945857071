export const getters = {
  approvalLevels(state) {
    return state.approvalLevels;
  },
  approvalLevelsById(state) {
    const byId = {};
    state.approvalLevels.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  mappedApprovalLevels(state) {
    return state.approvalLevels.map((al) => ({
      name: al.name,
      id: al.id,
    }));
  },
};
