var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isMobile
        ? _c(
            "v-app-bar",
            { staticClass: "header" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", "align-self": "center" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "justify-space-around" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: _vm.shouldHideTitle ? 1 : 2 },
                            },
                            [
                              _c("TravelTrackerLogo", {
                                staticClass: "logo pt-1",
                                attrs: { short: _vm.shouldHideTitle },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-center pa-0",
                              attrs: { cols: "8" },
                            },
                            [
                              _c(
                                "v-toolbar-items",
                                [
                                  _vm._l(_vm.menuItems, function (item) {
                                    return [
                                      _vm.hasAccess({ module: item.title })
                                        ? _c(
                                            "v-btn",
                                            {
                                              key: item.title,
                                              staticClass:
                                                "nav-button text-capitalize mx-3 white--text",
                                              attrs: {
                                                to: item.to,
                                                "active-class":
                                                  "nav-button--active",
                                                plain: "",
                                              },
                                            },
                                            [
                                              _vm.shouldHideTitle
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "hide-title",
                                                          attrs: { size: "24" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.icon)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            left: "",
                                                            size: "24",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.icon)
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.title) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex align-center justify-end py-0 px-4",
                              attrs: { cols: "2" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    color:
                                      _vm.colors[_vm.currentFiscalYear.color]
                                        .color,
                                    dark: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.fiscalYearModal = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentSemester?.name) +
                                      " "
                                  ),
                                  !_vm.shouldHideTitle
                                    ? _c("span", [
                                        _vm._v(
                                          "(FY: " +
                                            _vm._s(
                                              _vm.currentFiscalYear?.name
                                            ) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "", rounded: false },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      right: "",
                                                      "data-cy":
                                                        "setup-dropdown",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { color: "white" } },
                                                  [_vm._v("mdi-account")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3364725828
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "center-text" },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.me.firstName &&
                                                      _vm.me.lastName
                                                      ? `${_vm.me.firstName} ${_vm.me.lastName}`
                                                      : _vm.me.displayName
                                                  )
                                                ),
                                              ]),
                                              _c("p", [
                                                _vm._v(_vm._s(_vm.me.email)),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _vm._l(
                                        _vm.userMenuItems,
                                        function (group, index) {
                                          return [
                                            _vm._l(group, function (uitem) {
                                              return [
                                                uitem.href
                                                  ? [
                                                      _c(
                                                        "a",
                                                        {
                                                          key: uitem.title,
                                                          staticClass:
                                                            "menu-link",
                                                          attrs: {
                                                            href: uitem.href,
                                                            target: "_blank",
                                                            rel: "noopener noreferrer",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              attrs: {
                                                                target:
                                                                  "_blank",
                                                                rel: "noopener noreferrer",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        left: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          uitem.icon
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        uitem.title
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : [
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          key: uitem.title,
                                                          attrs: {
                                                            href:
                                                              _vm.fullUrl +
                                                              uitem.to,
                                                            "data-cy":
                                                              "user-menu",
                                                            "data-menu-title":
                                                              uitem.title,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      uitem.icon
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    uitem.title
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                              ]
                                            }),
                                            group.length
                                              ? _c("v-divider", { key: index })
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.logoutUser()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("mdi-exit-to-app")]
                                              ),
                                              _vm._v(" Log Out "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-app-bar",
                {
                  attrs: {
                    color: "primary z-10",
                    dark: "",
                    fixed: "",
                    "clipped-left": "",
                    elevation: "2",
                  },
                },
                [
                  _c("v-app-bar-nav-icon", {
                    on: {
                      click: function ($event) {
                        _vm.drawer = true
                      },
                    },
                  }),
                  _c(
                    "v-app-bar-title",
                    { staticClass: "pl-0" },
                    [_c("TravelTrackerLogo", { staticClass: "logo" })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-navigation-drawer",
                {
                  attrs: {
                    absolute: "",
                    temporary: "",
                    app: "",
                    clipped: "",
                    width: "95vw",
                  },
                  model: {
                    value: _vm.drawer,
                    callback: function ($$v) {
                      _vm.drawer = $$v
                    },
                    expression: "drawer",
                  },
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "nav-list", attrs: { nav: "" } },
                    [
                      _c(
                        "v-list-item-group",
                        {
                          attrs: {
                            "active-class": "deep-primary--text text--accent-4",
                          },
                          model: {
                            value: _vm.group,
                            callback: function ($$v) {
                              _vm.group = $$v
                            },
                            expression: "group",
                          },
                        },
                        [
                          _vm._l(_vm.menuItems, function (item) {
                            return _c(
                              "v-list-item",
                              { key: item.title, attrs: { to: item.to } },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                              ],
                              1
                            )
                          }),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.logoutUser()
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-exit-to-app")])],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Logout")]),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          model: {
            value: _vm.fiscalYearModal,
            callback: function ($$v) {
              _vm.fiscalYearModal = $$v
            },
            expression: "fiscalYearModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                        _vm._v("Select a Fiscal Year"),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _vm.canViewSemesterSelector
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "text-capitalize",
                                  attrs: {
                                    depressed: "",
                                    dense: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.openFiscalYearSettings },
                                },
                                [_vm._v(" Manage Fiscal Years ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _vm.canViewSemesterSelector
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "text-capitalize",
                                  attrs: {
                                    depressed: "",
                                    dense: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.openSemesterSettings },
                                },
                                [_vm._v(" Manage School Years ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.selectableFiscalYears, function (fiscalYear) {
                      return _c(
                        "v-list-group",
                        {
                          key: fiscalYear.id,
                          attrs: { "no-action": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "px-0" },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color:
                                                    _vm.colors[fiscalYear.color]
                                                      .color,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-checkbox-blank-circle"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(fiscalYear?.name)),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c("v-list-item-action-text", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(fiscalYear.fromDate) +
                                                  " ~ " +
                                                  _vm._s(fiscalYear.toDate) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: fiscalYear.active,
                            callback: function ($$v) {
                              _vm.$set(fiscalYear, "active", $$v)
                            },
                            expression: "fiscalYear.active",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ml-16" },
                            [
                              _c("v-label", [
                                _vm._v("School Years / Sessions"),
                              ]),
                              _vm._l(
                                _vm.semestersByFiscalYearId[fiscalYear.id],
                                function (semester) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: semester.id,
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectSemester(
                                            fiscalYear,
                                            semester
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color:
                                                  _vm.colors[semester.color]
                                                    .color,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "mdi-checkbox-blank-circle"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(semester?.name)),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-list-item-action-text", [
                                            _vm._v(
                                              _vm._s(semester.fromDate) +
                                                " ~ " +
                                                _vm._s(semester.toDate)
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.fiscalYearModal = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }