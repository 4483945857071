export const getters = {
  calendarTripRequests(state) {
    return state.calendarTripRequests;
  },
  calendarTripRequestsById(state) {
    const byId = {};
    state.calendarTripRequests.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  calendarAssignments(state) {
    return state.calendarAssignments;
  },
  calendarAssignmentsById(state) {
    const byId = {};
    state.calendarAssignments.forEach((e) => (byId[e.id] = e));
    return byId;
  },
};
