var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "panel", attrs: { cols: "4" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", "data-cy": "create-client-btn" },
                  on: { click: _vm.createClient },
                },
                [_vm._v("Create Client")]
              ),
              _c(
                "v-list",
                { attrs: { "two-line": "" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      staticClass: "transparentBorder",
                      attrs: { "active-class": "colorBorder" },
                      model: {
                        value: _vm.selectedClient,
                        callback: function ($$v) {
                          _vm.selectedClient = $$v
                        },
                        expression: "selectedClient",
                      },
                    },
                    _vm._l(_vm.clients, function (client, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.showForm = false
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(_vm._s(client.districtName)),
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          client.data.modules.routing
                                            ? _c(
                                                "v-icon",
                                                { attrs: { large: "" } },
                                                [_vm._v("mdi-alpha-r-circle")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                color: "blue",
                                                label: "",
                                                outlined: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  client.data.modules.tier.toLocaleUpperCase()
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-subtitle",
                                    {
                                      staticClass: "text--primary",
                                      attrs: {
                                        "data-cy": "client-name",
                                        "client-name": client.name,
                                      },
                                    },
                                    [_vm._v(_vm._s(client.name) + " ")]
                                  ),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(client.org)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          index < _vm.clients.length - 1
                            ? _c("v-divider", { key: index })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm,
                  expression: "showForm",
                },
              ],
              staticClass: "panel",
              attrs: { cols: "8" },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-form",
                    { attrs: { "data-cy": "client-form" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.form.name,
                                      label: "Name",
                                      placeholder: "e.g. lancasterpa",
                                      required: "",
                                      "error-messages": _vm.getValidationErrors,
                                      "data-cy": "client-form-name",
                                    },
                                    on: { blur: _vm.validateName },
                                    model: {
                                      value: _vm.form.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "name",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "7" } },
                                [
                                  _c("v-combobox", {
                                    attrs: {
                                      value: _vm.form.districtName,
                                      items: _vm.districts,
                                      "item-text": "label",
                                      "item-value": "name",
                                      label: "Search District",
                                      "data-cy": "client-form-district",
                                    },
                                    on: {
                                      "update:search-input":
                                        _vm.searchDistricts,
                                      change: _vm.handleDistrictChange,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              {
                                                attrs: {
                                                  "data-cy":
                                                    "client-district-list",
                                                  "district-name":
                                                    data.item.name,
                                                },
                                                on: { click: data.on.click },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        _vm._s(data.item.name)
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.$v.form.districtName.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.districtName,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.form.districtName.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "2" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.form.state,
                                      label: "State",
                                      required: "",
                                      "error-messages": _vm.handleErrors(
                                        _vm.$v.form.state
                                      ),
                                      readonly: "",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$v.form.state.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.$v.form.state.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.state,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.form.state.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("span", [_vm._v("Center Point")]),
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.form.locationLng,
                                      required: "",
                                      "error-messages": _vm.handleErrors(
                                        _vm.$v.form.locationLng
                                      ),
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$v.form.locationLng.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.$v.form.locationLng.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.locationLng,
                                          "$model",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "$v.form.locationLng.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("span", { staticClass: "white--text" }, [
                                    _vm._v("-"),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.form.locationLat,
                                      required: "",
                                      "error-messages": _vm.handleErrors(
                                        _vm.$v.form.locationLat
                                      ),
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$v.form.locationLat.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.$v.form.locationLat.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.locationLat,
                                          "$model",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "$v.form.locationLat.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "12", md: "3" } }, [
                                _c("span", [_vm._v("BBox")]),
                                _c("p", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.$v.form.bbox.$model.join(", ")
                                      ) +
                                      ")"
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.ezrDomains,
                                      label: "Routing Host",
                                      clearable: "",
                                      hint: "Syncing data from routing will take a moment - please be patient",
                                      "persistent-hint": "",
                                    },
                                    model: {
                                      value: _vm.$v.form.routingHost.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.routingHost,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression: "$v.form.routingHost.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                color: "success",
                                "data-cy": "client-save",
                                loading: _vm.saving,
                              },
                              on: { click: _vm.create },
                            },
                            [_vm._v("Save")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.selectedClient != null
            ? _c(
                "v-col",
                { staticClass: "panel", attrs: { cols: "8" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-2",
                      attrs: { color: "success" },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Save")]
                  ),
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "fixed-tabs": "",
                        "background-color": "primary",
                        dark: "",
                      },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c("v-tabs-slider"),
                      _c("v-tab", { attrs: { href: "#general" } }, [
                        _vm._v(" General "),
                      ]),
                      _c("v-tab", { attrs: { href: "#modules" } }, [
                        _vm._v(" Tiers & Addons "),
                      ]),
                      _c("v-tab", { attrs: { href: "#users" } }, [
                        _vm._v(" Users "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      staticClass: "tabs",
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        { key: 1, attrs: { value: "general" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("h4", [_vm._v("District Name")]),
                                      _c("v-text-field", {
                                        attrs: {
                                          value:
                                            _vm.clients[_vm.selectedClient]
                                              .districtName,
                                        },
                                        model: {
                                          value:
                                            _vm.clients[_vm.selectedClient]
                                              .districtName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.clients[_vm.selectedClient],
                                              "districtName",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "clients[selectedClient].districtName",
                                        },
                                      }),
                                      _c("h4", [_vm._v("Address")]),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ]?.streetAddress,
                                                  label: "Street Address",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].streetAddress,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ],
                                                      "streetAddress",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].streetAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                md: "6",
                                                lg: "4",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ]?.apartmentUnitFloor,
                                                  label:
                                                    "Apartment, Unit, Floor, etc.",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].apartmentUnitFloor,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ],
                                                      "apartmentUnitFloor",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].apartmentUnitFloor",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                md: "4",
                                                lg: "4",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ]?.city,
                                                  label: "City",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].city,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ],
                                                      "city",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].city",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                md: "4",
                                                lg: "4",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ]?.state,
                                                  label: "State",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].state,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ],
                                                      "state",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].state",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                md: "4",
                                                lg: "4",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ]?.zipCode,
                                                  label: "Zip Code",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].zipCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ],
                                                      "zipCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].zipCode",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("h4", [_vm._v("Coordinates")]),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ]?.data.default.location[1],
                                                  label: "Latitude (y)",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.default.location[1],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.default.location,
                                                      1,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.default.location[1]",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ]?.data.default.location[0],
                                                  label: "Longitude (x)",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.default.location[0],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.default.location,
                                                      0,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.default.location[0]",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("h4", [_vm._v("Boundaries")]),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ]?.data.default.bbox[1],
                                                  label: "Latitude (y)",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.default.bbox[1],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.default.bbox,
                                                      1,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.default.bbox[1]",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ]?.data.default.bbox[0],
                                                  label: "Longitude (x)",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.default.bbox[0],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.default.bbox,
                                                      0,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.default.bbox[0]",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ]?.data.default.bbox[3],
                                                  label: "Latitude (y)",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.default.bbox[3],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.default.bbox,
                                                      3,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.default.bbox[3]",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ]?.data.default.bbox[2],
                                                  label: "Longitude (x)",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.default.bbox[2],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.default.bbox,
                                                      2,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.default.bbox[2]",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "mb-4" }, [
                                    _c("h4", [_vm._v("Site")]),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: `https://${_vm.host}/${
                                            _vm.clients[_vm.selectedClient].name
                                          }#/`,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " https://" +
                                            _vm._s(_vm.host) +
                                            "/" +
                                            _vm._s(
                                              _vm.clients[_vm.selectedClient]
                                                .name
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("h4", [_vm._v("Authentication")]),
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          staticClass: "mt-2 mb-4",
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.clients[_vm.selectedClient]
                                                .data.authentication,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.clients[_vm.selectedClient]
                                                  .data,
                                                "authentication",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "clients[selectedClient].data.authentication",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "gdic" } },
                                            [_vm._v("GDIC")]
                                          ),
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "tam" } },
                                            [_vm._v("TAM")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.clients[_vm.selectedClient].data
                                        .authentication === "tam"
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: "Group Attributes",
                                                      hint: "Enter Group Attributes here and click enter, if any. Leave empty to accept all users",
                                                      "persistent-hint": "",
                                                    },
                                                    on: {
                                                      keyup: function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        return _vm.addTAMAttribute()
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.tamAttribute,
                                                      callback: function ($$v) {
                                                        _vm.tamAttribute = $$v
                                                      },
                                                      expression:
                                                        "tamAttribute",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "attribute-box",
                                                    },
                                                    [
                                                      _c(
                                                        "ul",
                                                        { staticClass: "pa-0" },
                                                        _vm._l(
                                                          _vm.clients[
                                                            _vm.selectedClient
                                                          ].data?.tamAttributes,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "li",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "attribute-list",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(item)
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "br-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          icon: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeTAMAttribute(
                                                                                index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-minus"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("h4", [
                                        _vm._v("Legacy Customer Migration"),
                                      ]),
                                      _c("v-checkbox", {
                                        attrs: {
                                          label:
                                            "Import customer legacy content",
                                          disabled:
                                            _vm.clients[_vm.selectedClient].data
                                              .legacy
                                              .legacyCustomerMigrationStatus ===
                                              "complete" ||
                                            _vm.clients[_vm.selectedClient].data
                                              .legacy
                                              .legacyCustomerMigrationStatus ===
                                              "migrating" ||
                                            _vm.disableMigrationButton,
                                        },
                                        model: {
                                          value:
                                            _vm.clients[_vm.selectedClient].data
                                              .legacy.isLegacyCustomer,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.clients[_vm.selectedClient]
                                                .data.legacy,
                                              "isLegacyCustomer",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "clients[selectedClient].data.legacy.isLegacyCustomer",
                                        },
                                      }),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                md: "6",
                                                lg: "3",
                                              },
                                            },
                                            [
                                              _vm.clients[_vm.selectedClient]
                                                .data.legacy.isLegacyCustomer
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "success",
                                                        disabled:
                                                          _vm.clients[
                                                            _vm.selectedClient
                                                          ].data.legacy
                                                            .legacyCustomerMigrationStatus ===
                                                            "complete" ||
                                                          _vm.clients[
                                                            _vm.selectedClient
                                                          ].data.legacy
                                                            .legacyCustomerMigrationStatus ===
                                                            "migrating" ||
                                                          _vm.disableMigrationButton,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.startMigration()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.migrationButtonStatus
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm.clients[_vm.selectedClient].data
                                            .legacy.isLegacyCustomer &&
                                          _vm.showMigrationStatus
                                            ? _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                    lg: "4",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    { staticClass: "mb-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.migrationStatus
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-progress-linear", {
                                                    attrs: {
                                                      color:
                                                        "light-green darken-4",
                                                      height: "10",
                                                      value:
                                                        _vm.migrationStatusValue,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("h4", [_vm._v("Email Server")]),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { dense: "" },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "3" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.server,
                                                  label: "Server",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.server,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.emailServer,
                                                      "server",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.emailServer.server",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "2" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.port,
                                                  label: "Port",
                                                  type: "number",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.port,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.emailServer,
                                                      "port",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.emailServer.port",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "2" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.secure,
                                                  label: "Secure",
                                                  dense: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.secure,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.emailServer,
                                                      "secure",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.emailServer.secure",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "3" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.username,
                                                  label: "Username",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.username,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.emailServer,
                                                      "username",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.emailServer.username",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "3" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.password,
                                                  label: "Password",
                                                  type: "password",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.password,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.emailServer,
                                                      "password",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.emailServer.password",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "3" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.email,
                                                  label: "From Email",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.emailServer.email,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.emailServer,
                                                      "email",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.emailServer.email",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.env !== "production"
                                    ? _c(
                                        "div",
                                        { staticClass: "mb-4" },
                                        [
                                          _c("h4", [_vm._v("Twilio")]),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { dense: "" },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "3",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.twilioOverride,
                                                      label:
                                                        "SMS Phone Number Override",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.twilioOverride,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.clients[
                                                            _vm.selectedClient
                                                          ].data,
                                                          "twilioOverride",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clients[selectedClient].data.twilioOverride",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "3",
                                                  },
                                                },
                                                [
                                                  _vm.clients[
                                                    _vm.selectedClient
                                                  ].data.twilioOverride?.length
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            disabled:
                                                              _vm.clients[
                                                                _vm
                                                                  .selectedClient
                                                              ].data
                                                                .twilioOverride
                                                                ?.length != 10,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.sendTestText,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Send Test Message "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("h4", [_vm._v("Yellowfin")]),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              value:
                                                _vm.clients[_vm.selectedClient]
                                                  .data.yellowfin.clientOrgRef,
                                              label: "Client Org Ref",
                                            },
                                            model: {
                                              value:
                                                _vm.clients[_vm.selectedClient]
                                                  .data.yellowfin.clientOrgRef,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.clients[
                                                    _vm.selectedClient
                                                  ].data.yellowfin,
                                                  "clientOrgRef",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "clients[selectedClient].data.yellowfin.clientOrgRef",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              value:
                                                _vm.clients[_vm.selectedClient]
                                                  .data.yellowfin
                                                  .organizationId,
                                              label: "Organization ID",
                                            },
                                            model: {
                                              value:
                                                _vm.clients[_vm.selectedClient]
                                                  .data.yellowfin
                                                  .organizationId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.clients[
                                                    _vm.selectedClient
                                                  ].data.yellowfin,
                                                  "organizationId",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "clients[selectedClient].data.yellowfin.organizationId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              value:
                                                _vm.clients[_vm.selectedClient]
                                                  .data.yellowfin.userGroupId,
                                              label: "User Group ID",
                                            },
                                            model: {
                                              value:
                                                _vm.clients[_vm.selectedClient]
                                                  .data.yellowfin.userGroupId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.clients[
                                                    _vm.selectedClient
                                                  ].data.yellowfin,
                                                  "userGroupId",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "clients[selectedClient].data.yellowfin.userGroupId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("h4", [_vm._v("Support")]),
                                  _c("v-checkbox", {
                                    attrs: {
                                      value:
                                        _vm.clients[_vm.selectedClient].data
                                          .support.showZoho,
                                      label: "Show Zendesk support",
                                      dense: "",
                                    },
                                    model: {
                                      value:
                                        _vm.clients[_vm.selectedClient].data
                                          .support.showZoho,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.clients[_vm.selectedClient].data
                                            .support,
                                          "showZoho",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "clients[selectedClient].data.support.showZoho",
                                    },
                                  }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mb-2 mt-12",
                                              attrs: {
                                                color: "error",
                                                "data-cy": "delete-client-btn",
                                              },
                                              on: {
                                                click:
                                                  _vm.openDeleteConfirmation,
                                              },
                                            },
                                            [_vm._v(" Delete Client ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { key: 2, attrs: { value: "modules" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("h4", [_vm._v("Tier")]),
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          staticClass: "mt-2 mb-4",
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.clients[_vm.selectedClient]
                                                .data.modules.tier,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.clients[_vm.selectedClient]
                                                  .data.modules,
                                                "tier",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "clients[selectedClient].data.modules.tier",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "pro" } },
                                            [_vm._v("PRO")]
                                          ),
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "elite" } },
                                            [_vm._v("ELITE")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("h4", [_vm._v("Addons")]),
                                  _vm.clients[_vm.selectedClient].data.modules
                                    .tier === "elite"
                                    ? _c("v-checkbox", {
                                        attrs: {
                                          value:
                                            _vm.clients[_vm.selectedClient].data
                                              .modules.roster.sync,
                                          label: "Roster Sync",
                                          dense: "",
                                        },
                                        model: {
                                          value:
                                            _vm.clients[_vm.selectedClient].data
                                              .modules.roster.sync,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.clients[_vm.selectedClient]
                                                .data.modules.roster,
                                              "sync",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "clients[selectedClient].data.modules.roster.sync",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.clients[_vm.selectedClient].data.modules
                                    .roster.sync &&
                                  _vm.clients[_vm.selectedClient].data.modules
                                    .tier === "elite"
                                    ? _c(
                                        "v-btn-toggle",
                                        {
                                          staticClass: "mt-2 mb-4",
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.clients[_vm.selectedClient]
                                                .data.modules.roster.syncType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.clients[_vm.selectedClient]
                                                  .data.modules.roster,
                                                "syncType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "clients[selectedClient].data.modules.roster.syncType",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "oneroster" } },
                                            [_vm._v("OneRoster")]
                                          ),
                                          _c(
                                            "v-btn",
                                            { attrs: { value: "ldap" } },
                                            [_vm._v("LDAP")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.clients[_vm.selectedClient].data.modules
                                    .roster.sync &&
                                  _vm.clients[_vm.selectedClient].data.modules
                                    .tier === "elite" &&
                                  _vm.clients[_vm.selectedClient].data.modules
                                    .roster.syncType === "ldap"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .server,
                                                      label: "Server",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .server,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.clients[
                                                            _vm.selectedClient
                                                          ].data.modules.roster,
                                                          "server",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clients[selectedClient].data.modules.roster.server",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .port,
                                                      label: "Port",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .port,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.clients[
                                                            _vm.selectedClient
                                                          ].data.modules.roster,
                                                          "port",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clients[selectedClient].data.modules.roster.port",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .dn,
                                                      label: "Bind DN",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .dn,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.clients[
                                                            _vm.selectedClient
                                                          ].data.modules.roster,
                                                          "dn",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clients[selectedClient].data.modules.roster.dn",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .password,
                                                      label: "Password",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .password,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.clients[
                                                            _vm.selectedClient
                                                          ].data.modules.roster,
                                                          "password",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clients[selectedClient].data.modules.roster.password",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.clients[_vm.selectedClient].data.modules
                                    .roster.sync &&
                                  _vm.clients[_vm.selectedClient].data.modules
                                    .tier === "elite" &&
                                  _vm.clients[_vm.selectedClient].data.modules
                                    .roster.syncType === "oneroster"
                                    ? _c(
                                        "div",
                                        { staticClass: "mb-6" },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .onerosterHost,
                                                      label: "Host",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .onerosterHost,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.clients[
                                                            _vm.selectedClient
                                                          ].data.modules.roster,
                                                          "onerosterHost",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clients[selectedClient].data.modules.roster.onerosterHost",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .onerosterClient,
                                                      type: "password",
                                                      label: "OneRoster Client",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .onerosterClient,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.clients[
                                                            _vm.selectedClient
                                                          ].data.modules.roster,
                                                          "onerosterClient",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clients[selectedClient].data.modules.roster.onerosterClient",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .onerosterSecret,
                                                      type: "password",
                                                      label: "OneRoster Secret",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clients[
                                                          _vm.selectedClient
                                                        ].data.modules.roster
                                                          .onerosterSecret,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.clients[
                                                            _vm.selectedClient
                                                          ].data.modules.roster,
                                                          "onerosterSecret",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clients[selectedClient].data.modules.roster.onerosterSecret",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.modules.roster
                                                      .adultRoster,
                                                  label: "Use Adult Roster",
                                                  dense: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.modules.roster
                                                      .adultRoster,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.modules.roster,
                                                      "adultRoster",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.modules.roster.adultRoster",
                                                },
                                              }),
                                              _vm.clients[_vm.selectedClient]
                                                .data.modules.roster.adultRoster
                                                ? _c(
                                                    "div",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _c("v-checkbox", {
                                                        staticClass: "ml-3",
                                                        attrs: {
                                                          value:
                                                            _vm.clients[
                                                              _vm.selectedClient
                                                            ].data.modules
                                                              .roster.teachers,
                                                          label: "Teachers",
                                                          dense: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.clients[
                                                              _vm.selectedClient
                                                            ].data.modules
                                                              .roster.teachers,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.clients[
                                                                _vm
                                                                  .selectedClient
                                                              ].data.modules
                                                                .roster,
                                                              "teachers",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "clients[selectedClient].data.modules.roster.teachers",
                                                        },
                                                      }),
                                                      _c("v-checkbox", {
                                                        staticClass: "ml-3",
                                                        attrs: {
                                                          value:
                                                            _vm.clients[
                                                              _vm.selectedClient
                                                            ].data.modules
                                                              .roster.aides,
                                                          label: "Aides",
                                                          dense: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.clients[
                                                              _vm.selectedClient
                                                            ].data.modules
                                                              .roster.aides,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.clients[
                                                                _vm
                                                                  .selectedClient
                                                              ].data.modules
                                                                .roster,
                                                              "aides",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "clients[selectedClient].data.modules.roster.aides",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "2",
                                                    lg: "1",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.testOneRoster()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Test")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "d-flex align-center",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "2",
                                                    lg: "1",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.oneRosterTestMessage
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("v-checkbox", {
                                    attrs: {
                                      value:
                                        _vm.clients[_vm.selectedClient].data
                                          .modules.routing,
                                      label: "Routing",
                                      dense: "",
                                    },
                                    model: {
                                      value:
                                        _vm.clients[_vm.selectedClient].data
                                          .modules.routing,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.clients[_vm.selectedClient].data
                                            .modules,
                                          "routing",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "clients[selectedClient].data.modules.routing",
                                    },
                                  }),
                                  _vm.clients[_vm.selectedClient].data.modules
                                    .routing
                                    ? _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.ezrDomains,
                                                  label: "Routing Host",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.clients[
                                                      _vm.selectedClient
                                                    ].data.routing.host,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.clients[
                                                        _vm.selectedClient
                                                      ].data.routing,
                                                      "host",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "clients[selectedClient].data.routing.host",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "3" } },
                                            [
                                              _vm.clients[_vm.selectedClient]
                                                .data.modules.routing
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        loading: _vm.syncing,
                                                        disabled: _vm.syncing,
                                                      },
                                                      on: {
                                                        click: _vm.syncEzr,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Sync with Routing "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("v-checkbox", {
                                    attrs: {
                                      value:
                                        _vm.clients[_vm.selectedClient].data
                                          .modules.yellowbus,
                                      label: "Yellow Bus",
                                      dense: "",
                                    },
                                    model: {
                                      value:
                                        _vm.clients[_vm.selectedClient].data
                                          .modules.yellowbus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.clients[_vm.selectedClient].data
                                            .modules,
                                          "yellowbus",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "clients[selectedClient].data.modules.yellowbus",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { key: 3, attrs: { value: "users" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "relative", attrs: { flat: "" } },
                            [
                              _c("setup-table", {
                                staticClass: "adminUserTable",
                                attrs: {
                                  items: _vm.users,
                                  headers: _vm.headers,
                                  noForm: "",
                                  hideCreate: "",
                                  showSearch: "",
                                  hideRowSelect: "",
                                  hideMenu: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: `item.actions`,
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex" },
                                            [
                                              _vm.isATAMUser(item)
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.impersonateUser(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("impersonate")]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "red",
                                                    dark: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeUser(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("remove")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "warnbox",
          attrs: { "max-width": "490" },
          model: {
            value: _vm.showDeleteConfirmation,
            callback: function ($$v) {
              _vm.showDeleteConfirmation = $$v
            },
            expression: "showDeleteConfirmation",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 red white--text" },
                [
                  _c("v-icon", { staticClass: "white--text px-1" }, [
                    _vm._v("mdi-alert"),
                  ]),
                  _vm._v(
                    " Delete Client " +
                      _vm._s(
                        _vm.selectedClient > 0
                          ? _vm.clients[_vm.selectedClient].name
                          : ""
                      ) +
                      "? "
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("p", { staticClass: "pt-2" }, [
                    _vm._v(" Are you sure you want to delete "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.selectedClient > 0
                            ? _vm.clients[_vm.selectedClient].name
                            : ""
                        )
                      ),
                    ]),
                    _vm._v(" ? "),
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("This action is irreversible!")]),
                  ]),
                  _c("p", [
                    _c("strong", { staticClass: "pr-1" }, [
                      _vm._v("Confirmation Code:"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "font-weight-bold red--text text-h6" },
                      [_vm._v(" " + _vm._s(_vm.randomCode) + " ")]
                    ),
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      label: "Enter Confirmation Code",
                      outlined: "",
                      rules: [
                        (v) => !!v || "Confirmation code is required",
                        (v) =>
                          v === _vm.randomCode ||
                          "Confirmation code doest not match",
                      ],
                      "data-cy": "delete-client-code",
                    },
                    model: {
                      value: _vm.confirmationCode,
                      callback: function ($$v) {
                        _vm.confirmationCode = $$v
                      },
                      expression: "confirmationCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        disabled:
                          _vm.confirmationCode !== _vm.randomCode || _vm.saving,
                        loading: _vm.saving,
                        "data-cy": "delete-client-confirm-btn",
                      },
                      on: { click: _vm.deleteClient },
                    },
                    [_vm._v(" Delete ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDeleteConfirmation = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }