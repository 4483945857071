export const getters = {
  me(state) {
    return state.me;
  },
  users(state) {
    return state.users;
  },
  usersById(state) {
    const byId = {};
    state.users.forEach((e) => (byId[e.id] = e));
    byId[0] = { id: 0, email: 'System', displayName: 'System', firstName: 'System', lastName: '' };
    return byId;
  },
  usersByEmail(state) {
    const byEmail = {};
    state.users.forEach((e) => (byEmail[e.email] = e));
    return byEmail;
  },
  userEmails(state) {
    return state.users.filter((e) => e.email).map((e) => e.email);
  },
  roles(state) {
    return state.roles;
  },
  rolesById(state) {
    const byId = {};
    state.roles.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  rolesByName(state) {
    const byName = {};
    state.roles.forEach((e) => (byName[e.name.toLowerCase()] = e));
    return byName;
  },
  roleAssignments(state) {
    return state.roleAssignments;
  },
  vehicleOwners(state) {
    return state.vehicleOwners;
  },
  uniqueVehicleOwners(state) {
    const map = new Map(state.vehicleOwners.map((pos) => [pos.userId, pos]));
    return [...map.values()];
  },
  vehicleOwnerByUserId(state) {
    const byId = {};
    state.vehicleOwners.forEach((e) => (byId[e.userId] = e));
    return byId;
  },
  yellowfinToken(state) {
    return state.yellowfinToken;
  },
  yellowfinRedirectUrl(state) {
    return state.yellowfinRedirectUrl;
  },
  canEditSetupTables(state) {
    return (
      state.me.is.superAdmin ||
      state.me.is.transportationAdmin ||
      (state.me.is.limitedAdmin && state.me.data.limitedAdmin.setupTables)
    );
  },
  canEditSettings(state) {
    return (
      state.me.is.superAdmin ||
      state.me.is.transportationAdmin ||
      (state.me.is.limitedAdmin && state.me.data.limitedAdmin.settings)
    );
  },
  isAdmin(state) {
    return state.me.is.superAdmin || state.me.is.transportationAdmin || state.me.is.limitedAdmin;
  },
  isVehicleOwnerEligible(state, getters, _, rootGetters) {
    if (getters.isAdmin) return true;

    const permissions = rootGetters['config/permissions'];

    return state?.me?.is?.vehicleOwner && permissions?.vehicleOwner?.manageInvoices;
  },
  canManageInvoice(state, getters) {
    return getters.isAdmin || state.me.is.finance || getters.isVehicleOwnerEligible;
  },
};
