export const mutations = {
  setTripRequests(state, tripRequests) {
    state.tripRequests = tripRequests;
  },
  sortTripRequests(state, { option, helpers }) {
    if (option.sortBy == 'locationName')
      state.tripRequests.forEach((e) => (e.locationName = helpers[e.locationId].name));

    if (option.order == 'desc')
      state.tripRequests.sort((a, b) => {
        return option.sortBy == 'leaveDate' || option.sortBy == 'locationName'
          ? b[option.sortBy].localeCompare(a[option.sortBy], undefined, { numeric: option.sortBy == 'leaveDate' })
          : b[option.sortBy] - a[option.sortBy];
      });
    else
      state.tripRequests.sort((a, b) => {
        return option.sortBy == 'leaveDate' || option.sortBy == 'locationName'
          ? a[option.sortBy].localeCompare(b[option.sortBy], undefined, { numeric: option.sortBy == 'leaveDate' })
          : a[option.sortBy] - b[option.sortBy];
      });
  },
  updateTripRequest(state, tripRequest) {
    const updateIndex = state.tripRequests.findIndex((e) => e.id == tripRequest.id);
    if (updateIndex >= 0) state.tripRequests[updateIndex] = JSON.parse(JSON.stringify(tripRequest));
    else state.tripRequests = [...state.tripRequests, tripRequest];
  },
  setCurrentTripRequestDetails(state, { tripRequest, tripTypeId, location, fundingSources }) {
    state.currentTripRequest = tripRequest;
    state.currentTripLocation = location;
    state.currentTripTypeId = tripTypeId;
    state.currentFundingSources = fundingSources;
  },
  setCurrentTripRequest(state, tripRequest) {
    state.currentTripRequest = tripRequest;
  },
  setCurrentTripLocation(state, location) {
    state.currentTripLocation = location;
  },
  seCurrentTripTypeId(state, tripTypeId) {
    state.currentTripTypeId = tripTypeId;
  },
  setCurrentFundingSources(state, fundingSources) {
    state.currentFundingSources = fundingSources;
  },
  isLoadingTrips(state, value) {
    state.isLoading = value;
  },
};
