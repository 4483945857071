var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "mainLayout" },
    [
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 5000, top: "", right: "", "multi-line": "" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "red", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.closeSnackbar()
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v("Close")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showSnackbar,
            callback: function ($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-column align-center mt-10" },
        [
          _c("TravelTrackerLogo", { staticClass: "login-image my-10" }),
          _c(
            "section",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.token,
                  expression: "!token",
                },
              ],
            },
            [
              !_vm.newUser && !_vm.forgotPassword
                ? _c(
                    "v-card",
                    { staticClass: "card pt-2" },
                    [
                      _vm.client === "admin"
                        ? _c(
                            "v-card-title",
                            {
                              staticClass:
                                "d-flex justify-center text-center blue-grey--text text--darken-3",
                            },
                            [
                              _c(
                                "h5",
                                { staticClass: "text-h5 font-weight-bold" },
                                [_vm._v("Admin Portal")]
                              ),
                            ]
                          )
                        : _c(
                            "v-card-title",
                            {
                              staticClass:
                                "d-flex justify-center text-center blue-grey--text text--darken-3",
                            },
                            [
                              _c(
                                "h5",
                                { staticClass: "text-h5 font-weight-bold" },
                                [_vm._v("Log In")]
                              ),
                            ]
                          ),
                      _vm.client !== "admin"
                        ? _c(
                            "v-card-subtitle",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "h4",
                                { staticClass: "text-subtitle-1 pt-4" },
                                [
                                  _vm._v(" New member? "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "cursor-pointer font-weight-bold blue-grey--text text--darken-3",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showRegistrationForm()
                                        },
                                      },
                                    },
                                    [_vm._v("Create an account")]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-card-text",
                        {
                          staticClass: "pa-4 d-flex flex-column justify-center",
                        },
                        [
                          _c(
                            "v-form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submitCredentials()
                                },
                              },
                            },
                            [
                              _c("v-text-field", {
                                ref: "email",
                                attrs: {
                                  rules: [
                                    ..._vm.handleErrors(
                                      _vm.$v.credentialsForm.username
                                    ),
                                    _vm.loginError ? _vm.loginError : true,
                                  ],
                                  label: "Email",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-account",
                                  required: "",
                                  type: "text",
                                  "data-cy": "login-email",
                                },
                                model: {
                                  value: _vm.$v.credentialsForm.username.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.credentialsForm.username,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.credentialsForm.username.$model",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.handleErrors(
                                    _vm.$v.credentialsForm.password
                                  ),
                                  label: "Password",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-lock",
                                  required: "",
                                  type: "password",
                                  "data-cy": "login-password",
                                },
                                model: {
                                  value: _vm.$v.credentialsForm.password.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.credentialsForm.password,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.credentialsForm.password.$model",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: _vm.$v.credentialsForm.$invalid,
                                    loading: _vm.isLoading,
                                    color: "primary",
                                    large: "",
                                    ripple: "",
                                    type: "submit",
                                    width: "100%",
                                    "data-cy": "login-btn",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.isLoading ? "Signing In" : "Login"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "mt-8" }, [
                            _c(
                              "p",
                              { staticClass: "text-subtitle-2 text-center" },
                              [
                                _vm._v(
                                  "© 2023 - " +
                                    _vm._s(_vm.currentYear) +
                                    " TransAct Communications, LLC."
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center my-5" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-decoration-none",
                                    attrs: {
                                      href: "https://transact.com/privacy-policy",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-link-variant"),
                                    ]),
                                    _vm._v(" Privacy Policy"),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "mx-3" }, [
                                  _vm._v("|"),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-decoration-none",
                                    attrs: {
                                      href: "https://transact.com/terms-of-use",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-link-variant"),
                                    ]),
                                    _vm._v(" Terms of Use"),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "blue-grey lighten-5" },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center", "no-gutters": "" } },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "cursor-pointer text-center blue-grey--text text--darken-3",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showForgotPasswordForm(
                                        _vm.$v.credentialsForm.username.$model
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Forgot your password?")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.newUser,
                      expression: "newUser",
                    },
                  ],
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitRegistrationForm()
                    },
                  },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "register-card" },
                    [
                      _c(
                        "v-card-actions",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "blue-grey--text text--darken-3 my-2",
                              attrs: { text: "", height: "32px" },
                              on: {
                                click: function ($event) {
                                  return _vm.showCredentialsForm(
                                    _vm.$v.registrationForm.email.$model
                                  )
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-arrow-left"),
                              ]),
                              _vm._v(" Back to Login "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "pa-0 d-flex justify-center text-center blue-grey--text text--darken-3",
                        },
                        [
                          _c(
                            "h5",
                            { staticClass: "text-h5 font-weight-bold" },
                            [_vm._v("Register")]
                          ),
                        ]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "pt-6" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c("v-text-field", {
                                    ref: "registerEmail",
                                    attrs: {
                                      disabled: _vm.registrationFoundEmailIsSet,
                                      rules: [
                                        _vm.isUserRegistered
                                          ? "Already registered with an EZTransportation application. Use your existing credentials to log in."
                                          : true,
                                        ..._vm.handleErrors(
                                          _vm.$v.registrationForm.email
                                        ),
                                      ],
                                      label: "Email",
                                      outlined: "",
                                      placeholder:
                                        "Let's start with your email",
                                      required: "",
                                      type: "email",
                                    },
                                    on: {
                                      input: () => {
                                        _vm.registrationFoundEmail = null
                                        _vm.isUserRegistered = false
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.registrationForm.email.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.registrationForm.email,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "$v.registrationForm.email.$model",
                                    },
                                  }),
                                  !_vm.isUserRegistered
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "d-flex mt-2",
                                          attrs: {
                                            disabled:
                                              _vm.$v.registrationForm.email
                                                .$invalid ||
                                              _vm.registrationFoundEmailIsSet,
                                            loading: _vm.isLoading,
                                            color: "primary",
                                            large: "",
                                            ripple: "",
                                            type: "submit",
                                          },
                                        },
                                        [_vm._v(" Next ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { dense: "", justify: "center" },
                                    },
                                    [
                                      _vm.registrationFoundEmailIsSet ||
                                      _vm.isUserRegistered
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                large: "",
                                                ripple: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clearRegistrationForm()
                                                },
                                              },
                                            },
                                            [_vm._v(" Try a different email? ")]
                                          )
                                        : _vm._e(),
                                      _vm.isUserRegistered
                                        ? _c("v-spacer")
                                        : _vm._e(),
                                      _vm.isUserRegistered
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "success",
                                                large: "",
                                                ripple: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showForgotPasswordForm(
                                                    _vm.$v.registrationForm
                                                      .email.$model
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Forgot your password? ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.registrationFoundEmailIsSet
                    ? _c(
                        "v-card",
                        { staticClass: "register-card my-4 pa-4" },
                        [
                          _c("v-card-subtitle", [
                            _vm._v(
                              " Complete the form below to register a user account. "
                            ),
                          ]),
                          _c(
                            "v-card-text",
                            { staticClass: "pa-4" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.handleErrors(
                                    _vm.$v.registrationForm.firstName
                                  ),
                                  label: "First Name",
                                  outlined: "",
                                  required: "",
                                  type: "text",
                                },
                                model: {
                                  value:
                                    _vm.$v.registrationForm.firstName.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.registrationForm.firstName,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.registrationForm.firstName.$model",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Middle Name (optional)",
                                  outlined: "",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.$v.registrationForm.midName.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.registrationForm.midName,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.registrationForm.midName.$model",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.handleErrors(
                                    _vm.$v.registrationForm.lastName
                                  ),
                                  label: "Last Name",
                                  outlined: "",
                                  required: "",
                                  type: "text",
                                },
                                model: {
                                  value:
                                    _vm.$v.registrationForm.lastName.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.registrationForm.lastName,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.registrationForm.lastName.$model",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.handleErrors(
                                    _vm.$v.registrationForm.phone
                                  ),
                                  label: "Phone Number (optional)",
                                  maxlength: "14",
                                  outlined: "",
                                  placeholder: "XXX-XXX-XXXX",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.$v.registrationForm.phone.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.registrationForm.phone,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.registrationForm.phone.$model",
                                },
                              }),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "append-icon": _vm.showNewPasswordText
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          rules: [
                                            ..._vm.handleErrors(
                                              _vm.$v.registrationForm.password
                                            ),
                                            ..._vm.handleErrors(
                                              _vm.$v.registrationForm
                                                .passwordStrength
                                            ),
                                          ],
                                          type: _vm.showNewPasswordText
                                            ? "text"
                                            : "password",
                                          label: "New Password",
                                          outlined: "",
                                        },
                                        on: {
                                          "click:append": function ($event) {
                                            _vm.showNewPasswordText =
                                              !_vm.showNewPasswordText
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.$v.registrationForm.password
                                              .$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.registrationForm.password,
                                              "$model",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "$v.registrationForm.password.$model",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("password-strength", {
                                    attrs: {
                                      password:
                                        _vm.$v.registrationForm.password.$model,
                                    },
                                    model: {
                                      value:
                                        _vm.$v.registrationForm.passwordStrength
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.registrationForm
                                            .passwordStrength,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.registrationForm.passwordStrength.$model",
                                    },
                                  }),
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-4" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "append-icon":
                                            _vm.showConfirmPasswordText
                                              ? "mdi-eye"
                                              : "mdi-eye-off",
                                          rules: _vm.handleErrors(
                                            _vm.$v.registrationForm
                                              .passwordConfirm,
                                            "New Password"
                                          ),
                                          type: _vm.showConfirmPasswordText
                                            ? "text"
                                            : "password",
                                          label: "Confirm New Password",
                                          outlined: "",
                                        },
                                        on: {
                                          "click:append": function ($event) {
                                            _vm.showConfirmPasswordText =
                                              !_vm.showConfirmPasswordText
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.$v.registrationForm
                                              .passwordConfirm.$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.registrationForm
                                                .passwordConfirm,
                                              "$model",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "$v.registrationForm.passwordConfirm.$model",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "px-4 mb-4 d-flex flex-row-reverse",
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mx-2",
                                          attrs: {
                                            disabled:
                                              _vm.$v.registrationForm.$invalid,
                                            loading: _vm.isLoading,
                                            color: "primary",
                                            large: "",
                                            ripple: "",
                                            type: "submit",
                                          },
                                        },
                                        [_vm._v("Submit")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.forgotPassword,
                      expression: "forgotPassword",
                    },
                  ],
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitForgotPassword()
                    },
                  },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "card" },
                    [
                      _c(
                        "v-card-actions",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "blue-grey--text text--darken-3",
                              attrs: { text: "", height: "32px" },
                              on: {
                                click: function ($event) {
                                  return _vm.showCredentialsForm(
                                    _vm.$v.forgotPasswordForm.email.$model
                                  )
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-arrow-left"),
                              ]),
                              _vm._v(" Back to Login "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-center text-center" },
                        [
                          _c(
                            "h5",
                            { staticClass: "text-h5 font-weight-bold" },
                            [_vm._v("Forgot Password")]
                          ),
                        ]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "d-flex flex-column justify-center" },
                        [
                          _c("p", [
                            _vm._v(
                              "Enter your email address to receive an email with a link to reset your password."
                            ),
                          ]),
                          _c("v-text-field", {
                            ref: "forgotPasswordEmail",
                            attrs: {
                              rules: _vm.handleErrors(
                                _vm.$v.forgotPasswordForm.email
                              ),
                              label: "Email",
                              outlined: "",
                              "prepend-inner-icon": "mdi-email",
                              required: "",
                              type: "email",
                            },
                            model: {
                              value: _vm.$v.forgotPasswordForm.email.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.forgotPasswordForm.email,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.forgotPasswordForm.email.$model",
                            },
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.$v.forgotPasswordForm.$invalid,
                                loading: _vm.isLoading,
                                color: "primary",
                                large: "",
                                ripple: "",
                                type: "submit",
                              },
                            },
                            [_vm._v(" Request Password Reset ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "section",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.token,
                  expression: "token",
                },
              ],
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.resetSuccess,
                          expression: "!resetSuccess",
                        },
                      ],
                    },
                    [
                      _c(
                        "v-form",
                        {
                          attrs: { value: _vm.$v.resetPasswordForm.$invalid },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submitResetPasswordForm()
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": _vm.showNewPasswordText
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: _vm.showNewPasswordText
                                        ? "text"
                                        : "password",
                                      rules: [
                                        ..._vm.handleErrors(
                                          _vm.$v.resetPasswordForm.password
                                        ),
                                        ..._vm.handleErrors(
                                          _vm.$v.resetPasswordForm
                                            .passwordStrength
                                        ),
                                      ],
                                      label: "New Password",
                                      outlined: "",
                                      required: "",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showNewPasswordText =
                                          !_vm.showNewPasswordText
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.resetPasswordForm.password
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.resetPasswordForm.password,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "$v.resetPasswordForm.password.$model",
                                    },
                                  }),
                                  _c("password-strength", {
                                    attrs: {
                                      password:
                                        _vm.$v.resetPasswordForm.password
                                          .$model,
                                    },
                                    model: {
                                      value:
                                        _vm.$v.resetPasswordForm
                                          .passwordStrength.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.resetPasswordForm
                                            .passwordStrength,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.resetPasswordForm.passwordStrength.$model",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": _vm.showConfirmPasswordText
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: _vm.showConfirmPasswordText
                                        ? "text"
                                        : "password",
                                      rules: _vm.handleErrors(
                                        _vm.$v.resetPasswordForm
                                          .passwordConfirm,
                                        "New Password"
                                      ),
                                      label: "Confirm New Password",
                                      outlined: "",
                                      required: "",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showConfirmPasswordText =
                                          !_vm.showConfirmPasswordText
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.resetPasswordForm.passwordConfirm
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.resetPasswordForm
                                            .passwordConfirm,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "$v.resetPasswordForm.passwordConfirm.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.$v.resetPasswordForm.$invalid,
                                        loading: _vm.isLoading,
                                        color: "success",
                                        type: "submit",
                                      },
                                    },
                                    [_vm._v("Update Password")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.resetSuccess,
                          expression: "resetSuccess",
                        },
                      ],
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "pb-0 d-flex flex-column align-center",
                            },
                            [
                              _c(
                                "h4",
                                { staticClass: "text-h5 font-weight-bold" },
                                [_vm._v("Password Reset Successfully")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "cursor-pointer",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showCredentialsForm()
                                    },
                                  },
                                },
                                [_vm._v("Return to Login")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }