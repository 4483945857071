<template>
  <main class="mainLayout">
    <v-snackbar v-model="showSnackbar" :timeout="5000" top right multi-line>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="closeSnackbar()">Close</v-btn>
      </template>
    </v-snackbar>

    <div class="d-flex flex-column align-center mt-10">
      <TravelTrackerLogo class="login-image my-10" />

      <section v-show="!token">
        <v-card v-if="!newUser && !forgotPassword" class="card pt-2">
          <v-card-title
            class="d-flex justify-center text-center blue-grey--text text--darken-3"
            v-if="client === 'admin'"
            ><h5 class="text-h5 font-weight-bold">Admin Portal</h5></v-card-title
          >

          <v-card-title class="d-flex justify-center text-center blue-grey--text text--darken-3" v-else
            ><h5 class="text-h5 font-weight-bold">Log In</h5></v-card-title
          >

          <v-card-subtitle class="text-center" v-if="client !== 'admin'">
            <h4 class="text-subtitle-1 pt-4">
              New member?
              <a @click="showRegistrationForm()" class="cursor-pointer font-weight-bold blue-grey--text text--darken-3"
                >Create an account</a
              >
            </h4>
          </v-card-subtitle>

          <v-card-text class="pa-4 d-flex flex-column justify-center">
            <v-form @submit.prevent="submitCredentials()">
              <v-text-field
                :rules="[...handleErrors($v.credentialsForm.username), loginError ? loginError : true]"
                label="Email"
                outlined
                prepend-inner-icon="mdi-account"
                ref="email"
                required
                type="text"
                v-model.trim="$v.credentialsForm.username.$model"
                data-cy="login-email"
              />

              <v-text-field
                :rules="handleErrors($v.credentialsForm.password)"
                label="Password"
                outlined
                prepend-inner-icon="mdi-lock"
                required
                type="password"
                v-model.trim="$v.credentialsForm.password.$model"
                data-cy="login-password"
              />

              <v-btn
                :disabled="$v.credentialsForm.$invalid"
                :loading="isLoading"
                color="primary"
                large
                ripple
                type="submit"
                width="100%"
                data-cy="login-btn"
              >
                {{ this.isLoading ? 'Signing In' : 'Login' }}
              </v-btn>
            </v-form>

            <div class="mt-8">
              <p class="text-subtitle-2 text-center">© 2023 - {{ currentYear }} TransAct Communications, LLC.</p>

              <div class="d-flex justify-center my-5">
                <a href="https://transact.com/privacy-policy" target="_blank" class="text-decoration-none"
                  ><v-icon small>mdi-link-variant</v-icon> Privacy Policy</a
                >

                <span class="mx-3">|</span>

                <a href="https://transact.com/terms-of-use" target="_blank" class="text-decoration-none"
                  ><v-icon small>mdi-link-variant</v-icon> Terms of Use</a
                >
              </div>
            </div>
          </v-card-text>

          <v-card-actions class="blue-grey lighten-5">
            <v-row justify="center" no-gutters>
              <a
                @click="showForgotPasswordForm($v.credentialsForm.username.$model)"
                class="cursor-pointer text-center blue-grey--text text--darken-3"
                >Forgot your password?</a
              >
            </v-row>
          </v-card-actions>
        </v-card>

        <v-form v-show="newUser" @submit.prevent="submitRegistrationForm()">
          <v-card class="register-card">
            <v-card-actions class="d-flex justify-center">
              <v-btn
                text
                height="32px"
                class="blue-grey--text text--darken-3 my-2"
                @click="showCredentialsForm($v.registrationForm.email.$model)"
              >
                <v-icon left>mdi-arrow-left</v-icon>
                Back to Login
              </v-btn>
            </v-card-actions>

            <v-card-title class="pa-0 d-flex justify-center text-center blue-grey--text text--darken-3"
              ><h5 class="text-h5 font-weight-bold">Register</h5></v-card-title
            >

            <v-card-text class="pt-6">
              <v-row dense>
                <v-col class="d-flex flex-column">
                  <v-text-field
                    :disabled="registrationFoundEmailIsSet"
                    :rules="[
                      isUserRegistered
                        ? 'Already registered with an EZTransportation application. Use your existing credentials to log in.'
                        : true,
                      ...handleErrors($v.registrationForm.email),
                    ]"
                    @input="
                      () => {
                        registrationFoundEmail = null;
                        isUserRegistered = false;
                      }
                    "
                    label="Email"
                    outlined
                    placeholder="Let's start with your email"
                    ref="registerEmail"
                    required
                    type="email"
                    v-model.trim="$v.registrationForm.email.$model"
                  />

                  <v-btn
                    :disabled="$v.registrationForm.email.$invalid || registrationFoundEmailIsSet"
                    :loading="isLoading"
                    class="d-flex mt-2"
                    color="primary"
                    large
                    ripple
                    type="submit"
                    v-if="!isUserRegistered"
                  >
                    Next
                  </v-btn>

                  <v-row class="mt-2" dense justify="center">
                    <v-btn
                      @click="clearRegistrationForm()"
                      text
                      large
                      ripple
                      v-if="registrationFoundEmailIsSet || isUserRegistered"
                    >
                      Try a different email?
                    </v-btn>

                    <v-spacer v-if="isUserRegistered"></v-spacer>

                    <v-btn
                      @click="showForgotPasswordForm($v.registrationForm.email.$model)"
                      color="success"
                      large
                      ripple
                      v-if="isUserRegistered"
                    >
                      Forgot your password?
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="register-card my-4 pa-4" v-if="registrationFoundEmailIsSet">
            <v-card-subtitle> Complete the form below to register a user account. </v-card-subtitle>

            <v-card-text class="pa-4">
              <v-text-field
                :rules="handleErrors($v.registrationForm.firstName)"
                label="First Name"
                outlined
                required
                type="text"
                v-model.trim="$v.registrationForm.firstName.$model"
              />

              <v-text-field
                label="Middle Name (optional)"
                outlined
                type="text"
                v-model="$v.registrationForm.midName.$model"
              />

              <v-text-field
                :rules="handleErrors($v.registrationForm.lastName)"
                label="Last Name"
                outlined
                required
                type="text"
                v-model.trim="$v.registrationForm.lastName.$model"
              />

              <v-text-field
                :rules="handleErrors($v.registrationForm.phone)"
                label="Phone Number (optional)"
                maxlength="14"
                outlined
                placeholder="XXX-XXX-XXXX"
                type="text"
                v-model.trim="$v.registrationForm.phone.$model"
              />

              <v-col>
                <v-row>
                  <v-text-field
                    :append-icon="showNewPasswordText ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[
                      ...handleErrors($v.registrationForm.password),
                      ...handleErrors($v.registrationForm.passwordStrength),
                    ]"
                    :type="showNewPasswordText ? 'text' : 'password'"
                    @click:append="showNewPasswordText = !showNewPasswordText"
                    label="New Password"
                    outlined
                    v-model.trim="$v.registrationForm.password.$model"
                  ></v-text-field>
                </v-row>

                <password-strength
                  :password="$v.registrationForm.password.$model"
                  v-model="$v.registrationForm.passwordStrength.$model"
                ></password-strength>

                <v-row class="mt-4">
                  <v-text-field
                    :append-icon="showConfirmPasswordText ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="handleErrors($v.registrationForm.passwordConfirm, 'New Password')"
                    :type="showConfirmPasswordText ? 'text' : 'password'"
                    @click:append="showConfirmPasswordText = !showConfirmPasswordText"
                    label="Confirm New Password"
                    outlined
                    v-model.trim="$v.registrationForm.passwordConfirm.$model"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-card-text>

            <v-card-actions>
              <v-row>
                <v-col class="px-4 mb-4 d-flex flex-row-reverse">
                  <v-btn
                    :disabled="$v.registrationForm.$invalid"
                    :loading="isLoading"
                    class="mx-2"
                    color="primary"
                    large
                    ripple
                    type="submit"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>

        <v-form v-show="forgotPassword" @submit.prevent="submitForgotPassword()">
          <v-card class="card">
            <v-card-actions class="d-flex justify-center">
              <v-btn
                @click="showCredentialsForm($v.forgotPasswordForm.email.$model)"
                text
                height="32px"
                class="blue-grey--text text--darken-3"
              >
                <v-icon left>mdi-arrow-left</v-icon>
                Back to Login
              </v-btn>
            </v-card-actions>

            <v-card-title class="d-flex justify-center text-center"
              ><h5 class="text-h5 font-weight-bold">Forgot Password</h5></v-card-title
            >

            <v-card-text class="d-flex flex-column justify-center">
              <p>Enter your email address to receive an email with a link to reset your password.</p>

              <v-text-field
                :rules="handleErrors($v.forgotPasswordForm.email)"
                label="Email"
                outlined
                prepend-inner-icon="mdi-email"
                ref="forgotPasswordEmail"
                required
                type="email"
                v-model.trim="$v.forgotPasswordForm.email.$model"
              />

              <v-btn
                :disabled="$v.forgotPasswordForm.$invalid"
                :loading="isLoading"
                color="primary"
                large
                ripple
                type="submit"
              >
                Request Password Reset
              </v-btn>
            </v-card-text>
          </v-card>
        </v-form>
      </section>

      <section v-show="token">
        <v-card>
          <v-card-text v-show="!resetSuccess">
            <v-form :value="$v.resetPasswordForm.$invalid" @submit.prevent="submitResetPasswordForm()">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    :append-icon="showNewPasswordText ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showNewPasswordText ? 'text' : 'password'"
                    :rules="[
                      ...handleErrors($v.resetPasswordForm.password),
                      ...handleErrors($v.resetPasswordForm.passwordStrength),
                    ]"
                    @click:append="showNewPasswordText = !showNewPasswordText"
                    label="New Password"
                    outlined
                    required
                    v-model.trim="$v.resetPasswordForm.password.$model"
                  ></v-text-field>

                  <password-strength
                    :password="$v.resetPasswordForm.password.$model"
                    v-model="$v.resetPasswordForm.passwordStrength.$model"
                  ></password-strength>

                  <v-text-field
                    :append-icon="showConfirmPasswordText ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showConfirmPasswordText ? 'text' : 'password'"
                    :rules="handleErrors($v.resetPasswordForm.passwordConfirm, 'New Password')"
                    @click:append="showConfirmPasswordText = !showConfirmPasswordText"
                    label="Confirm New Password"
                    outlined
                    required
                    v-model.trim="$v.resetPasswordForm.passwordConfirm.$model"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="auto">
                  <v-btn :disabled="$v.resetPasswordForm.$invalid" :loading="isLoading" color="success" type="submit"
                    >Update Password</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-text v-show="resetSuccess">
            <v-row>
              <v-col class="pb-0 d-flex flex-column align-center">
                <h4 class="text-h5 font-weight-bold">Password Reset Successfully</h4>

                <v-btn color="primary" class="cursor-pointer" @click="showCredentialsForm()">Return to Login</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </section>
    </div>
  </main>
</template>

<script>
import { email, maxLength, required, requiredIf, sameAs, minValue } from 'vuelidate/lib/validators';

import api from '@/shared/api.service';

import PasswordStrength from '@/components/PasswordStrength.vue';
import TravelTrackerLogo from '@/components/shared/Logo.vue';
import PasswordFormValidations from '@/models/PasswordFormValidation.model';

import { handleErrors } from '@/util';

export default {
  name: 'gdicLogin',
  props: ['token', 'client'],
  components: { TravelTrackerLogo, PasswordStrength },
  data() {
    return {
      forgotPassword: false,
      handleErrors,
      isLoading: false,
      isUserRegistered: false,
      loginButtonText: 'Login',
      loginError: '',
      newUser: false,
      passwordResetMessage: '',
      registerEmailError: '',
      registrationFoundEmail: null,
      resetSuccess: false,
      showConfirmPasswordText: false,
      showNewPasswordText: false,
      showSnackbar: false,
      snackbarText: '',
      forgotPasswordForm: {
        email: '',
      },
      credentialsForm: {
        password: '',
        username: '',
      },
      registrationForm: {
        email: '',
        firstName: '',
        lastName: '',
        midName: '',
        password: '',
        passwordConfirm: '',
        passwordStrength: 0,
        phone: '',
      },
      resetPasswordForm: {
        password: '',
        passwordConfirm: '',
        passwordStrength: 0,
      },
    };
  },
  validations() {
    return {
      credentialsForm: {
        username: { email, required },
        password: { required },
      },
      registrationForm: {
        email: { email, required },
        firstName: { required },
        midName: {},
        lastName: { required },
        phone: { maxLength: maxLength(14) },
        ...PasswordFormValidations(3),
      },
      forgotPasswordForm: {
        email: { email, required },
      },
      resetPasswordForm: PasswordFormValidations(3),
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.email.focus();
    });

    const url = window.location.href;
    const regex = new RegExp('[?&]token(=([^&#]*)|&|#|$)');

    const results = regex.exec(url);
    if (!results || !!results[2]) return;

    const found = decodeURIComponent(results[2].replace(/\+/g, ' '));
    if (!found || found.length !== 96) return;

    localStorage.setItem('token', found);

    const match = window.location.href.match(/\.com\/(\w+)/);
    if (match[1]) window.location.href = '/' + match[1];
  },
  computed: {
    registrationFoundEmailIsSet() {
      return Number.isInteger(this.registrationFoundEmail);
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    clearCredentialsForm() {
      this.credentialsForm = {
        password: '',
        username: '',
      };

      this.$nextTick(() => this.$v.credentialsForm.$reset());
    },
    clearForgotPasswordForm() {
      this.forgotPasswordForm = {
        email: '',
      };

      this.$nextTick(() => this.$v.forgotPasswordForm.$reset());
    },
    clearRegistrationForm() {
      this.isUserRegistered = false;
      this.registrationFoundEmail = null;
      this.registrationForm = {
        email: '',
        firstName: '',
        lastName: '',
        midName: '',
        password: '',
        passwordConfirm: '',
        passwordStrength: 0,
        phone: '',
      };

      this.$nextTick(() => this.$v.registrationForm.$reset());
    },
    clearResetPasswordForm() {
      this.resetPasswordForm = {
        password: '',
        passwordConfirm: '',
        passwordStrength: 0,
      };

      this.$nextTick(() => this.$v.resetPasswordForm.$reset());
    },
    closeSnackbar() {
      this.showSnackbar = false;
      this.snackbarText = '';
    },
    showCredentialsForm(email = '') {
      this.loginError = '';
      this.registerEmailError = '';
      this.registrationFoundEmail = null;

      this.$nextTick(() => {
        this.clearCredentialsForm();
        this.credentialsForm.username = email;

        this.clearForgotPasswordForm();
        this.clearRegistrationForm();
        this.clearResetPasswordForm();

        this.forgotPassword = false;
        this.newUser = false;
        this.resetSuccess = false;
        this.showConfirmPasswordText = false;
        this.showNewPasswordText = false;
        this.isUserRegistered = false;
      });
    },
    showForgotPasswordForm(email = '') {
      this.forgotPassword = true;
      this.newUser = false;
      this.showConfirmPasswordText = false;
      this.showNewPasswordText = false;

      if (email) this.forgotPasswordForm.email = email;

      this.$nextTick(() => {
        this.clearRegistrationForm();
        this.$refs.forgotPasswordEmail.focus();
      });
    },
    showRegistrationForm() {
      this.newUser = true;
      this.clearRegistrationForm();

      this.$nextTick(() => {
        this.$refs.registerEmail.focus();
      });
    },
    submitCheckEmail() {
      if (this.$v.registrationForm.email.$invalid || this.isLoading) return;

      this.isLoading = true;
      this.registerEmailError = '';

      api
        .post('/api/user/find', { email: this.$v.registrationForm.email.$model })
        .then((response) => {
          if (response.registered) {
            this.isUserRegistered = true;
            return;
          }

          this.registrationFoundEmail = response.found;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitCredentials() {
      if (this.$v.credentialsForm.$invalid || this.isLoading) return;

      this.isLoading = true;

      api
        .post('/api/user/find', { email: this.$v.credentialsForm.username.$model.toLowerCase() })
        .then((userAccessResponse) => {
          const userNotFound = userAccessResponse?.registered === false && userAccessResponse?.notFoundInDB === true;

          if (this.client != 'admin' && userNotFound) {
            throw new Error('User not found');
          }

          return api.post('/login', this.$v.credentialsForm.$model);
        })
        .catch((userAccessError) => {
          this.loginError = userAccessError ? userAccessError.message : 'User not found';
        })
        .then((loginResponse) => {
          if (!loginResponse || loginResponse?.message) throw Error('Invalid Credentials');

          location.reload();
        })
        .catch((loginError) => {
          this.loginError = loginError ? loginError.message : 'Invalid Credentials';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitForgotPassword() {
      if (this.$v.forgotPasswordForm.$invalid || this.isLoading) return;

      this.isLoading = true;
      this.passwordResetMessage = '';

      if (!this.client) this.client = window.location.pathname.replace('/', '');

      api
        .post('/api/forgot', { email: this.$v.forgotPasswordForm.email.$model, client: this.client })
        .then((response) => {
          if (!response.done) {
            this.passwordResetMessage = response.message ?? 'Could not send password reset email';

            return;
          }

          this.showSnackbar = true;
          this.snackbarText = 'The password reset email has been sent.';

          this.clearForgotPasswordForm();
        })
        .catch(() => {
          this.snackbarText = 'Could not send password reset email';
          this.showSnackbar = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitRegistration() {
      if (this.$v.registrationForm.$invalid || this.isLoading) return;

      this.isLoading = true;

      api
        .post('/api/register', {
          ...this.$v.registrationForm.$model,
          retype: this.$v.registrationForm.passwordConfirm.$model,
          email: this.$v.registrationForm.email.$model.toLowerCase(),
        })
        .then((response) => {
          if (response.id) {
            this.snackbarText = 'Registration was successful, please login.';
            this.showSnackbar = true;
          }

          this.showCredentialsForm();
          this.clearRegistrationForm();
        })
        .catch(() => {
          this.snackbarText = 'Failed to register your account.';
          this.showSnackbar = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitRegistrationForm() {
      this.registrationFoundEmailIsSet ? this.submitRegistration() : this.submitCheckEmail();
    },
    submitResetPasswordForm() {
      if (this.$v.resetPasswordForm.$invalid || this.isLoading) return;

      this.isLoading = true;

      api
        .post('/api/forgot/reset', { token: this.token, newPassword: this.$v.resetPasswordForm.password.$model })
        .then(() => {
          this.resetSuccess = true;
          this.clearResetPasswordForm();
        })
        .catch(() => {
          this.snackbarText = 'Failed to reset password.';
          this.showSnackbar = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    credentialsForm: {
      deep: true,
      handler(credentials) {
        if (!credentials) return;
        if (!credentials.username.length || (!credentials.password.length && this.loginError)) {
          this.loginError = '';
        }
      },
    },
    forgotPasswordForm: {
      deep: true,
      handler(forgotPasswordForm) {
        if (!forgotPasswordForm) return;
        if (!forgotPasswordForm.email.length) this.passwordResetMessage = '';
      },
    },
    registrationForm: {
      deep: true,
      immediate: true,
      handler(registrationForm) {
        if (!registrationForm) return;

        if (registrationForm.phone.length) {
          let phone = (registrationForm.phone || '').replace(/\D/g, '').substring(0, 10);
          const mask = phone.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

          phone = !mask[2] ? mask[1] : '(' + mask[1] + ') ' + mask[2] + (mask[3] ? '-' + mask[3] : '');

          if (registrationForm.phone != phone) registrationForm.phone = phone;
        }
      },
    },
  },
};
</script>

<style scoped>
.mainLayout {
  align-items: start;
  background: linear-gradient(to bottom, #00294d 0%, #009eec 100%);
  display: grid;
  height: 100%;
  justify-content: center;
}
.login-image {
  filter: brightness(0) invert(1);
  width: 350px;
}
@media only screen and (min-width: 600px) {
  .card {
    width: 400px;
  }
  .register-card {
    width: 600px;
  }
  .login-image {
    width: 600px;
    max-height: 100px;
  }
}
.cursor-pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
