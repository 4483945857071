<template>
  <v-menu
    :close-on-content-click="false"
    :offset-x="isOffsetX"
    :offset-y="isOffsetY"
    :open-on-hover="isSubMenu"
    :transition="transition"
    :value="openMenu"
    v-model="menuOpened"
  >
    <template v-slot:activator="{ on }">
      <v-btn :color="color" v-if="icon" v-on="on" data-cy="setup-table-menu-btn">
        <v-icon>
          {{ icon }}
        </v-icon>
      </v-btn>

      <v-list-item
        class="d-flex justify-space-between"
        v-else-if="isSubMenu"
        v-on="on"
        data-cy="setup-table-submenu"
        :data-submenu-name="name"
      >
        {{ name }}

        <div class="flex-grow-1"></div>

        <v-icon> mdi-chevron-right </v-icon>
      </v-list-item>

      <v-btn
        :disabled="isMenuItemDisabled(disabled)"
        :color="color"
        @click="openMenu = true"
        text
        v-else
        v-on="on"
        class="text-capitalize"
        data-cy="setup-table-menu-btn"
      >
        {{ name }} <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-list :class="isSubMenu ? 'submenu' : ''">
      <v-list-item class="selectall" @click="selectAllItems(menuItems)" v-if="selectAllMenu.includes(name)">
        Select All
      </v-list-item>

      <template v-for="(item, index) in menuItems">
        <v-divider :key="index" v-if="item.isDivider" />

        <sub-menu
          :is-offset-x="true"
          :is-offset-y="false"
          :is-open-on-hover="true"
          :is-sub-menu="true"
          :key="index"
          :menu-items="item.menu"
          :name="item.name"
          @sub-menu-click="emitClickEvent"
          v-else-if="item.menu"
        />

        <v-list-item
          :key="index"
          @click="emitClickEvent(item)"
          :disabled="isMenuItemDisabled(item.disabled)"
          v-else
          data-cy="setup-table-submenu"
          :data-submenu-name="item.name"
        >
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'sub-menu',
  inject: ['eventHub'],
  components: {},
  data: () => ({
    openMenu: false,
    isOpenOnHover: false,
    menuOpened: false,
    selectAllMenu: ['Trip Type', 'Trip Event', 'Location', 'Vehicle Type'],
    currentPage: '',
  }),
  props: {
    name: String,
    icon: String,
    menuItems: Array,
    color: { type: String, default: '#000' },
    isOffsetX: { type: Boolean, default: false },
    isOffsetY: { type: Boolean, default: true },
    //isOpenOnHover: { type: Boolean, default: false },
    isSubMenu: { type: Boolean, default: false },
    transition: { type: String, default: 'scale-transition' },
    showDropDown: { type: Boolean, default: false },
    disabled: { type: [Boolean, Function], default: false },
  },
  created() {
    this.currentPage = this.$route.name;
  },
  methods: {
    emitClickEvent(item) {
      this.$emit('sub-menu-click', item);
      if (this.currentPage !== 'Trip Requests' && this.currentPage !== 'Assignments') {
        this.openMenu = false;
        this.menuOpened = false;
      } else if (item.name === 'Date Range') {
        this.openMenu = false;
        this.menuOpened = false;
      }
    },
    isMenuItemDisabled(disabled) {
      if (typeof disabled === 'function') {
        return disabled();
      }
      return disabled;
    },
    selectAllItems(menuItems) {
      menuItems.forEach((item) => {
        item.selected = true;
        this.$emit('sub-menu-click', item);
      });
    },
  },
  watch: {
    menuOpened: function () {
      this.isOpenOnHover = !this.menuOpened;
    },
  },
};
</script>

<style>
.submenu {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.selectall {
  font-weight: 500;
}
</style>

// https://codepen.io/mourticarius/pen/RwxeKdz
