<template>
  <main class="mainLayout">
    <v-snackbar v-model="showSnackbar" :timeout="5000" top right multi-line>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="closeSnackbar()"> Close </v-btn>
      </template>
    </v-snackbar>

    <div class="d-flex flex-column align-center mt-10">
      <TravelTrackerLogo class="login-image my-10" />

      <v-card class="card">
        <v-card-text class="pa-5">
          <v-row class="justify-center mb-3">
            <div class="d-flex flex-column justify-center">
              <h4 class="text-h5 text-center font-weight-bold my-5">{{ pageText.SIGN_IN }}</h4>
            </div>
          </v-row>

          <div class="d-flex flex-column justify-center pt-3" v-if="showEmailField">
            <v-text-field
              label="Email"
              ref="email"
              v-model="username"
              required
              outlined
              prepend-inner-icon="mdi-account"
              :disabled="disableField"
              :error-messages="errorText"
              @keyup.enter="submit()"
            />

            <v-text-field
              type="password"
              v-if="showGDICPasswordField"
              label="Password"
              ref="password"
              v-model="password"
              required
              outlined
              prepend-inner-icon="mdi-lock"
              @keyup.enter="submit()"
            />

            <v-btn
              class="btn-flat waves-effect waves-light login-button"
              type="button"
              large
              ripple
              :disabled="disableButton"
              @click="submit()"
            >
              {{ buttonText }}
            </v-btn>

            <div class="mt-10">
              <p class="text-subtitle-2 text-center">© 2023 - {{ currentYear }} TransAct Communications, LLC.</p>

              <div class="d-flex justify-center my-5">
                <a href="https://transact.com/privacy-policy" target="_blank" class="text-decoration-none"
                  ><v-icon small>mdi-link-variant</v-icon> Privacy Policy</a
                >

                <span class="mx-3">|</span>

                <a href="https://transact.com/terms-of-use" target="_blank" class="text-decoration-none"
                  ><v-icon small>mdi-link-variant</v-icon> Terms of Use</a
                >
              </div>
            </div>
          </div>

          <div v-if="showLoginOptions" class="d-flex flex-column align-center mb-3">
            <v-btn @click="displayEmailField()" class="my-4" color="secondary" plain
              ><v-icon dark left> mdi-arrow-left </v-icon>Go Back to Login</v-btn
            >

            <v-btn
              v-for="(option, count) in loginOptions"
              :key="count"
              elevation="3"
              large
              color="secondary"
              class="mb-3"
              @click="verifyApplication(option)"
            >
              <v-icon class="mr-3">mdi-{{ option.provider.toLowerCase() }}</v-icon>
              Sign in with {{ option.provider }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </main>
</template>

<script>
import TravelTrackerLogo from '@/components/shared/Logo.vue';
import api from '../../shared/api.service';
export default {
  name: 'tamLogin',
  props: ['token', 'client'],
  components: { TravelTrackerLogo },
  data() {
    return {
      username: '',
      password: '',
      pageText: {
        SIGN_IN: 'Sign into your account',
        NEXT: 'Next',
        VERIFYING: 'Verifying',
        IDP_AUTO_SIGN: 'Redirecting to IDP...',
        SIGNIN_COMPLETE: 'Completing Sign in...',
        INCORRECT_CREDENTIALS: 'Incorrect Credentials',
      },
      disableField: false,
      disableButton: false,
      buttonText: 'Next',
      errorText: '',
      showSnackbar: false,
      snackbarText: '',
      loginOptions: [],
      showEmailField: true,
      showLoginOptions: false,
      showGDICPasswordField: false,
    };
  },
  mounted: async function () {
    this.$nextTick(function () {
      const params = new URLSearchParams(window.location.search);

      if (params.has('code') && params.has('state')) {
        const code = params.get('code');
        const email = params.get('state');

        this.disableButton = true;
        this.disableField = true;
        this.username = email;
        this.buttonText = 'Signing In...';
        this.signIn(code, email);
      } else {
        this.$refs.email.focus();
      }
    });
  },
  computed: {
    currentYear() {
      const date = new Date();

      return date.getFullYear();
    },
  },
  methods: {
    async submit() {
      if (!this.username.length) return;
      if (this.showGDICPasswordField) return this.gdicSubmit();

      this.buttonText = this.pageText.VERIFYING;
      this.disableButton = true;

      try {
        if (this.username.includes('@transact.com' || this.username.includes('@ezrouting.com'))) {
          const emailFound = await this.checkEmail();

          if (!emailFound) {
            return this.$nextTick(function () {
              this.buttonText = this.pageText.NEXT;
              this.disableButton = false;
              this.errorText = `User not found in Client`;
            });
          }

          return this.$nextTick(function () {
            this.errorText = ``;
            this.buttonText = 'Sign In';
            this.disableButton = false;
            this.showGDICPasswordField = true;
            this.$nextTick(() => {
              this.$refs.password.focus();
            });
          });
        }

        const response = await api.post('tam/getClientIDP');

        this.loginOptions = response.domainUrls;

        if (!this.loginOptions || !this.loginOptions.length) {
          this.snackbarText = 'IDPs have not been configured for this client';

          return (this.showSnackbar = true);
        }

        if (this.loginOptions.length === 1) return this.verifyApplication(this.loginOptions[0]);

        this.displayLoginOptions();
      } catch (error) {
        this.snackbarText = 'Something went wrong getting Domain info';
        this.showSnackbar = true;
      } finally {
        if (this.loginOptions?.length !== 1) this.resetButton();
      }
    },
    async gdicSubmit() {
      this.errorText = '';
      this.buttonText = this.pageText.VERIFYING;
      this.disableButton = true;

      const credentials = {
        username: this.username,
        password: this.password,
      };

      api
        .post('/login', credentials)
        .then((response) => {
          if (response.message) {
            this.$nextTick(function () {
              this.snackbarText = 'Incorrect credentials';
              this.showSnackbar = true;
            });
          } else {
            location.reload();
          }
        })
        .catch(() => {
          this.snackbarText = 'Something went wrong';
          this.showSnackbar = true;
          this.resetButton();
        });
    },
    async verifyApplication(option) {
      const protocol = 'https://';

      window.location.assign(
        `${process.env.VUE_APP_TAM_URL}/oauth/authorize?_s=5&client_id=tt_id&redirect_uri=${protocol}${option.url}&response_type=code&scope=tam.oauthdata&state=${this.username}`
      );
    },
    async signIn(code, email) {
      try {
        this.errorText = '';

        const response = await api.post('/tam/signIn', { code, email, uri: window.location.href.split('?')[0] });

        if (response.email) window.location.assign(`${window.location.href.split('?')[0]}#/dashboard`);
      } catch (error) {
        this.disableButton = false;
        this.disableField = false;
        this.username = '';

        this.$nextTick(function () {
          this.snackbarText = error.message;
          this.showSnackbar = true;
        });
      }
    },
    async checkEmail() {
      if (!this.username) return;

      try {
        const response = await api.post('/api/user/find', { email: this.username });

        if (!response.inClientDB) return false;

        return response.found > 0;
      } catch (error) {
        this.snackbarText = 'Something went wrong verifying email';
        this.showSnackbar = true;
        this.resetButton();
      }
    },
    closeSnackbar() {
      this.showSnackbar = false;
      this.snackbarText = '';
    },
    resetButton() {
      this.disableButton = false;
      this.showGDICPasswordField = false;
      this.buttonText = this.pageText.NEXT;
    },
    displayEmailField() {
      this.showLoginOptions = false;
      this.showEmailField = true;
    },
    displayLoginOptions() {
      this.showEmailField = false;
      this.showLoginOptions = true;
    },
  },
  watch: {
    username() {
      this.showGDICPasswordField = false;

      if (!this.username.length) {
        this.disableButton = true;

        if (this.errorText) this.errorText = '';
      }

      const regex = /^[[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

      if (this.username.length && !this.username.match(regex)) this.disableButton = true;
      else if (this.disableButton && this.disableField) return;
      else this.resetButton();
    },
  },
};
</script>

<style scoped>
.mainLayout {
  background: linear-gradient(to bottom, #00294d 0%, #009eec 100%);
  display: grid;
  height: 100vh;
  justify-content: center;
  align-items: start;
}
.login-image {
  width: 350px;
  filter: brightness(0) invert(1);
}
@media only screen and (min-width: 600px) {
  .card {
    width: 400px;
  }
  .login-image {
    width: 600px;
    max-height: 100px;
  }
}
.error {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: white;
}
.v-btn.v-btn--has-bg.login-button {
  background-color: #0d619f;
  color: white;
}
</style>
