var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.authentication === "gdic"
        ? _c("gdic-login", {
            attrs: { token: _vm.resetToken, client: _vm.client },
          })
        : _vm._e(),
      _vm.authentication === "tam"
        ? _c("tam-login", {
            attrs: { token: _vm.resetToken, client: _vm.client },
          })
        : _vm._e(),
      !_vm.authentication
        ? _c(
            "div",
            { staticClass: "defaultLayout" },
            [_c("TravelTrackerLogo", { staticClass: "mainLogo" })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }