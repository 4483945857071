var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.containerClasses },
    [_c("v-img", { attrs: { src: _vm.logoUrl, contain: "", alt: "logo" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }