var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "z-10",
          attrs: {
            app: "",
            "clipped-left": "",
            dark: "",
            color: "primary",
            height: "60",
            "elevate-on-scroll": "",
          },
        },
        [
          _c("h3", [_vm._v("Admin Dashboard")]),
          _c("v-spacer"),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "", rounded: false },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            { attrs: { icon: "", right: "" } },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-account")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-title", { staticClass: "center-text" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.me.firstName && _vm.me.lastName
                                ? `${_vm.me.firstName} ${_vm.me.lastName}`
                                : _vm.me.displayName
                            )
                          ),
                        ]),
                        _c("p", [_vm._v(_vm._s(_vm.me.email))]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.logoutUser()
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-exit-to-app"),
                          ]),
                          _vm._v(" Logout "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            clipped: "",
            permanent: "",
            "expand-on-hover": "",
            absolute: "",
          },
        },
        [
          _c(
            "v-list",
            { staticClass: "mt-15", attrs: { dense: "", nav: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  staticClass: "transparentBorder",
                  attrs: { "active-class": "colorBorder" },
                  model: {
                    value: _vm.activePage,
                    callback: function ($$v) {
                      _vm.activePage = $$v
                    },
                    expression: "activePage",
                  },
                },
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "v-list-item",
                    { key: item.title },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "pl-15" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _vm.activePage == 0 ? _c("clients") : _vm._e(),
              _vm.activePage == 1 ? _c("users") : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }