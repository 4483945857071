export default {
  getSmsLogs,
  getSmsLogByDriverId,
};

import api from '@/shared/api.service';
async function getSmsLogs() {
  return await api.get('/api/sms-log');
}

async function getSmsLogByDriverId(id) {
  return await api.get(`/api/sms-log/${id}`);
}
