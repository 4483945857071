export const mutations = {
  setAssignments(state, assignments) {
    state.assignments = assignments.sort((a, b) => a.tripRequestId - b.tripRequestId);
  },
  sortAssignments(state, { option, helpers }) {
    if (option.sortBy == 'locationName')
      state.assignments.forEach((e) => (e.locationName = helpers[e.locationId].name));

    if (option.order == 'desc')
      state.assignments.sort((a, b) => {
        return option.sortBy == 'leaveDate' || option.sortBy == 'locationName'
          ? b[option.sortBy].localeCompare(a[option.sortBy], undefined, { numeric: option.sortBy == 'leaveDate' })
          : b[option.sortBy] - a[option.sortBy];
      });
    else
      state.assignments.sort((a, b) => {
        return option.sortBy == 'leaveDate' || option.sortBy == 'locationName'
          ? a[option.sortBy].localeCompare(b[option.sortBy], undefined, { numeric: option.sortBy == 'leaveDate' })
          : a[option.sortBy] - b[option.sortBy];
      });
  },
  updateAssignment(state, assignment) {
    if (assignment.id) {
      const updateIndex = state.assignments.findIndex((e) => e.id == assignment.id);
      if (updateIndex >= 0) state.assignments[updateIndex] = assignment;
      else state.assignments = [...state.assignments, assignment];
    }
  },
  removeAssignmentById(state, id) {
    state.assignments = state.assignments.filter((e) => e.id != id);
  },
  removeAssignmentsByTripRequestId(state, tripRequestId) {
    state.assignments = state.assignments.filter((e) => e.tripRequestId != tripRequestId);
  },
  isLoadingAssignments(state, value) {
    state.isLoading = value;
  },
  async setFilteredAssignmentIds(state, assignments) {
    state.filteredAssignmentIds = assignments.map((assignment) => assignment.id);
  },
};
