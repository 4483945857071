import vehicleTypeApi from '@/apis/vehicleType';

export const GET_VEHICLE_TYPES = 'getVehicleTypes';
export const GET_HIDDEN_VEHICLE_TYPES = 'getHiddenVehicleTypes';
export const GET_VEHICLE_TYPE = 'getVehicleType';
export const SAVE_VEHICLE_TYPE = 'saveVehicleType';
export const DELETE_VEHICLE_TYPES = 'deleteVehicleTypes';

export const actions = {
  async [GET_VEHICLE_TYPES]({ commit }, options) {
    const result = await vehicleTypeApi.getVehicleTypes(options);
    commit('setVehicleTypes', result);
  },
  async [GET_HIDDEN_VEHICLE_TYPES]() {
    return await vehicleTypeApi.getVehicleTypes({ forTable: 1, hidden: 1 });
  },
  async [GET_VEHICLE_TYPE](_, id) {
    const result = await vehicleTypeApi.getVehicleType(id);
    return result;
  },
  async [SAVE_VEHICLE_TYPE](_, vehicleType) {
    return await vehicleTypeApi.saveVehicleType(vehicleType);
  },
  async [DELETE_VEHICLE_TYPES](_, ids) {
    return await vehicleTypeApi.deleteVehicleTypes(ids);
  },
};
