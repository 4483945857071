var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showNewUser = true
                        },
                      },
                    },
                    [_vm._v("Add User")]
                  ),
                ],
                1
              ),
              _c(
                "v-list",
                { attrs: { "two-line": "" } },
                [
                  _c(
                    "v-list-item-group",
                    _vm._l(_vm.users, function (user, index) {
                      return _c(
                        "div",
                        { key: user.uuid },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(user.firstName) +
                                        " " +
                                        _vm._s(user.lastName)
                                    ),
                                  ]),
                                  _c(
                                    "v-list-item-subtitle",
                                    { staticClass: "text--primary" },
                                    [_vm._v(_vm._s(user.email) + " ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c(
                                    "v-list-item-action-text",
                                    [
                                      _vm.users.length > 2
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeUser(user)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "red" } },
                                                [_vm._v("mdi-delete")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          index < _vm.users.length - 1
                            ? _c("v-divider", { key: index })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "8" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showNewUser,
                    expression: "showNewUser",
                  },
                ],
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    value: _vm.newUserEmail,
                                    label: "Email",
                                  },
                                  model: {
                                    value: _vm.newUserEmail,
                                    callback: function ($$v) {
                                      _vm.newUserEmail =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "newUserEmail",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.found.email
                          ? _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: { click: _vm.checkExist },
                              },
                              [_vm._v("Confirm Email")]
                            )
                          : _c(
                              "v-btn",
                              {
                                attrs: { color: "success" },
                                on: { click: _vm.addUser },
                              },
                              [_vm._v("Add User")]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }