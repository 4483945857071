var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.loading
        ? [
            _c(
              "div",
              { staticClass: "loading-screen" },
              [
                _c("TravelTrackerLogo", { attrs: { short: "" } }),
                _c("v-progress-linear", {
                  staticClass: "progress",
                  attrs: {
                    rounded: "",
                    color: "primary",
                    height: "10",
                    indeterminate: "",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm.showAdminPortal
        ? _c("admin")
        : _c("main-layout", { staticClass: "blue-grey lighten-5" }),
      _c(
        "v-snackbar",
        {
          attrs: { top: "", timeout: _vm.snackbarOptions.timeout },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: _vm.snackbarOptions.color, text: "" },
                        on: {
                          click: function ($event) {
                            _vm.notify = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Close ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.notify,
            callback: function ($$v) {
              _vm.notify = $$v
            },
            expression: "notify",
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.snackbarOptions.message ? _vm.snackbarOptions.message : ""
              ) +
              " "
          ),
        ]
      ),
      _vm.yellowfinURL && _vm.yfToken && _vm.yfToken.length
        ? _c("script", {
            tag: "component",
            attrs: {
              src: `${_vm.yellowfinURL}/JsAPI/v3?token=${_vm.yfToken}&clientOrg=${_vm.yfClientOrg}`,
            },
          })
        : _vm._e(),
      _vm.clientConfig.data &&
      _vm.clientConfig.data.support.showZoho &&
      !_vm.me.isTTAdmin
        ? _c("script", {
            tag: "component",
            attrs: {
              id: "ze-snippet",
              nonce: _vm.nonce,
              defer: "",
              src: "https://static.zdassets.com/ekr/snippet.js?key=c29fe58e-94ed-4971-be34-bb3499bd1d0c",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }