export const getters = {
  tripRequestConfig(state) {
    return state.tripRequestConfig;
  },
  driverConfig(state) {
    return state.driverConfig;
  },
  invoiceConfig(state) {
    return state.invoiceConfig;
  },
  vehicleOwnerConfig(state) {
    return state.vehicleOwnerConfig;
  },
  cffConfig(state) {
    return state.cffConfig;
  },
  budgetCodeConfig(state) {
    return state.budgetCodeConfig || [];
  },
  calendarConfig(state) {
    return state.calendarConfig;
  },
  leadDaysConfig(state) {
    return state.leadDaysConfig;
  },
  specialTravelNeeds(state) {
    return state.specialTravelNeeds;
  },
  specialIndicators(state) {
    return state.specialIndicators;
  },
  districtWideRates(state) {
    return state.districtWideRates;
  },
  fuelPrices(state) {
    return state.fuelPrices;
  },
  additionalInvoiceCosts(state) {
    return state.additionalInvoiceCosts;
  },
  permissions(state) {
    return state.permissions;
  },
  tripDateTimes(state) {
    return state.tripDateTimes;
  },
  blockedDates(state) {
    return state.tripDateTimes.filter((e) => e.type === 'blocked');
  },
  specialDates(state) {
    return state.tripDateTimes.filter((e) => e.type === 'special');
  },
  tripTimeMessages(state) {
    return state.tripDateTimes.filter((e) => e.type === 'time');
  },
  notifyConfig(state) {
    return state.notifyConfig;
  },
  customFormFieldTypes(state) {
    return Object.values(state.customFormFieldTypes).sort((a, b) => a.value - b.value);
  },
  budgetCodeConfigMapped(state) {
    return state.budgetCodeConfig.reduce((acc, budgetCode) => {
      if (budgetCode?.name) {
        acc[budgetCode.name.toLowerCase()] = budgetCode;
      }
      return acc;
    }, {});
  },
  schoolFinanceDriverEditable(state, getters, rootState, rootGetters) {
    const me = rootGetters['user/me'];

    return me?.is?.schoolFinance && state.permissions?.schoolFinance?.changeDrivers === true;
  },
  schoolFinanceMileageEditable(state, getters, rootState, rootGetters) {
    const me = rootGetters['user/me'];

    return me?.is?.schoolFinance && state.permissions?.schoolFinance?.enterMileage === true;
  },
};
