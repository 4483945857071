export default {
  saveApprover,
  deleteApprover,
  getApprovers,
};

import api from '@/shared/api.service';
async function getApprovers(query) {
  const url = query ? `/api/approver?${query}` : '/api/approver';
  return await api.get(url);
}
async function saveApprover(obj) {
  return await api.post('/api/approver', obj);
}

async function deleteApprover(id) {
  return await api.delete(`/api/approver/${id}`);
}
