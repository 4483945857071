export const getters = {
  smsLogs(state) {
    return state.smsLogs;
  },
  smsLogsBySendToId: (state) => {
    return state.smsLogs.reduce((acc, log) => {
      const { sendToId } = log;
      if (!acc[sendToId]) {
        acc[sendToId] = [];
      }
      acc[sendToId].push(log);
      return acc;
    }, {});
  },
};
