import driverApi from '@/apis/driver';

export const GET_DRIVERS = 'getDrivers';
export const GET_HIDDEN_DRIVERS = 'getHiddenDrivers';
export const GET_DRIVER = 'getDriver';
export const GET_AVAILABLE_DRIVERS = 'getAvailableDrivers';
export const SAVE_DRIVER = 'saveDriver';
export const ZERO_OUT = 'zeroOutDrivers';
export const DELETE_DRIVERS = 'deleteDrivers';

export const actions = {
  async [GET_DRIVERS]({ commit }, options) {
    const result = await driverApi.getDrivers(options);
    commit('setDrivers', result);
  },
  async [GET_HIDDEN_DRIVERS]() {
    return await driverApi.getDrivers({ forTable: 1, hidden: 1 });
  },
  async [GET_DRIVER](_, id) {
    const result = await driverApi.getDriver(id);
    return result;
  },
  async [GET_AVAILABLE_DRIVERS](_, obj) {
    const result = await driverApi.getAvailableDrivers(obj);
    return result;
  },
  async [SAVE_DRIVER](_, driver) {
    return await driverApi.saveDriver(driver);
  },
  async [ZERO_OUT]() {
    return await driverApi.zeroOutDrivers();
  },
  async [DELETE_DRIVERS](_, ids) {
    return await driverApi.deleteDrivers(ids);
  },
};
