export default {
  getAssignmentById,
  getAssignments,
  getAssignmentsByUserId,
  getAssignmentsForCalendar,
  saveAssignment,
  batchAssign,
  acceptAssignment,
  deleteAssignment,
  printAssignments,
  exportAssignments,
  sendDriverNotification,
  sendWeeklyNotifications,
  computeFuelCostByAssignmentId,
  updateAssignmentDriverAssistantById,
  saveAssignmentVehicleNotes,
};

//////////////

import api from '@/shared/api.service';

async function getAssignmentById(id) {
  return await api.get(`/api/assignment/${id}`);
}

async function getAssignments(params) {
  let str = `/api/assignment`;
  if (params && params.fromDate && params.fromTime && params.toDate && params.toTime)
    str += `?fromDate=${params.fromDate}&fromTime=${params.fromTime}&toDate=${params.toDate}&toTime=${params.toTime}&type=${params.type}`;
  if (params && params.includeStops) str += '?includeStops=true';
  if (params && params.tripRequestIds) str += `?tripRequestIds=${params.tripRequestIds}&includeStops=true`;
  return await api.get(str);
}

async function getAssignmentsByUserId(userId) {
  return await api.get(`/api/assignment/user/${userId || 0}`);
}

async function getAssignmentsForCalendar(options) {
  let url = '/api/calendar/assignment';
  if (options?.startDate && options?.endDate) url += `?start=${options.startDate}&end=${options.endDate}`;

  return await api.get(url);
}

async function saveAssignment(params) {
  return await api.post('/api/assignment', params);
}

async function saveAssignmentVehicleNotes(params) {
  return await api.post('/api/assignment/vehicle-notes', params);
}

async function batchAssign(params) {
  return await api.post('/api/assignment/batch', params);
}

async function acceptAssignment(params) {
  return await api.put('/api/assignment/accept', params);
}

async function deleteAssignment(id) {
  return await api.delete(`/api/assignment/${id}`);
}

async function printAssignments(params) {
  return await api.post('/api/assignment/print', params);
}

async function exportAssignments(params) {
  return await api.post('/api/assignment/export', params);
}

async function sendDriverNotification(assignmentId) {
  return await api.post(`/api/assignment/notify/${assignmentId}`);
}

async function sendWeeklyNotifications() {
  return await api.post('/api/assignment/notify/weekly');
}

async function computeFuelCostByAssignmentId(assignmentId) {
  return await api.get(`/api/assignment/compute-fuel-cost/${assignmentId}`);
}

async function updateAssignmentDriverAssistantById({ params }) {
  return await api.post(`/api/assignment/update-driver-assistant`, params);
}
