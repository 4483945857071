<template>
  <div>
    <v-app-bar class="header" v-if="!isMobile">
      <v-col cols="12" align-self="center">
        <v-container fluid class="pa-0">
          <v-row class="justify-space-around">
            <v-col :cols="shouldHideTitle ? 1 : 2" class="pa-0">
              <TravelTrackerLogo class="logo pt-1" :short="shouldHideTitle" />
            </v-col>

            <v-col cols="8" class="d-flex justify-center pa-0">
              <v-toolbar-items>
                <template v-for="item in menuItems">
                  <v-btn
                    :key="item.title"
                    :to="item.to"
                    active-class="nav-button--active"
                    class="nav-button text-capitalize mx-3 white--text"
                    plain
                    v-if="hasAccess({ module: item.title })"
                  >
                    <div v-if="shouldHideTitle">
                      <v-icon size="24" class="hide-title">{{ item.icon }}</v-icon>
                    </div>

                    <div v-else>
                      <v-icon left size="24">{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </div>
                  </v-btn>
                </template>
              </v-toolbar-items>
            </v-col>

            <v-col cols="2" class="d-flex align-center justify-end py-0 px-4">
              <v-btn class="mr-2" @click="fiscalYearModal = true" :color="colors[currentFiscalYear.color].color" dark>
                {{ currentSemester?.name }} <span v-if="!shouldHideTitle">(FY: {{ currentFiscalYear?.name }})</span>
              </v-btn>

              <v-menu offset-y :rounded="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon right v-bind="attrs" v-on="on" data-cy="setup-dropdown">
                    <v-icon color="white">mdi-account</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-list-item-title class="center-text">
                      <strong>{{
                        me.firstName && me.lastName ? `${me.firstName} ${me.lastName}` : me.displayName
                      }}</strong>
                      <p>{{ me.email }}</p>
                    </v-list-item-title>
                  </v-list-item>

                  <v-divider></v-divider>

                  <template v-for="(group, index) in userMenuItems">
                    <template v-for="uitem in group">
                      <template v-if="uitem.href">
                        <a
                          :key="uitem.title"
                          :href="uitem.href"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="menu-link"
                        >
                          <v-list-item target="_blank" rel="noopener noreferrer">
                            <v-list-item-title>
                              <v-icon left>{{ uitem.icon }}</v-icon>
                              {{ uitem.title }}
                            </v-list-item-title>
                          </v-list-item>
                        </a>
                      </template>

                      <template v-else>
                        <v-list-item
                          :key="uitem.title"
                          :href="fullUrl + uitem.to"
                          data-cy="user-menu"
                          :data-menu-title="uitem.title"
                        >
                          <v-list-item-title>
                            <v-icon left>{{ uitem.icon }}</v-icon>
                            {{ uitem.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </template>

                    <v-divider :key="index" v-if="group.length"></v-divider>
                  </template>

                  <v-list-item @click="logoutUser()">
                    <v-list-item-title>
                      <v-icon left>mdi-exit-to-app</v-icon>
                      Log Out
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-app-bar>

    <div v-else class="d-flex">
      <v-app-bar color="primary z-10" dark fixed clipped-left elevation="2">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        <v-app-bar-title class="pl-0"><TravelTrackerLogo class="logo" /></v-app-bar-title>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary app clipped width="95vw">
        <v-list nav class="nav-list">
          <v-list-item-group v-model="group" active-class="deep-primary--text text--accent-4">
            <v-list-item v-for="item in menuItems" :key="item.title" :to="item.to">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="logoutUser()">
              <v-list-item-icon>
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>

    <v-dialog v-model="fiscalYearModal" width="800">
      <v-card>
        <v-card-title>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" md="4">Select a Fiscal Year</v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3">
              <v-btn
                v-if="canViewSemesterSelector"
                @click="openFiscalYearSettings"
                depressed
                dense
                color="primary"
                class="text-capitalize"
              >
                Manage Fiscal Years
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                v-if="canViewSemesterSelector"
                @click="openSemesterSettings"
                depressed
                dense
                color="primary"
                class="text-capitalize"
              >
                Manage School Years
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-group
              v-for="fiscalYear in selectableFiscalYears"
              :key="fiscalYear.id"
              v-model="fiscalYear.active"
              no-action
            >
              <template v-slot:activator>
                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon :color="colors[fiscalYear.color].color">mdi-checkbox-blank-circle</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ fiscalYear?.name }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text>
                      {{ fiscalYear.fromDate }} ~ {{ fiscalYear.toDate }}
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </template>

              <div class="ml-16">
                <v-label>School Years / Sessions</v-label>
                <v-list-item
                  v-for="semester in semestersByFiscalYearId[fiscalYear.id]"
                  :key="semester.id"
                  @click="selectSemester(fiscalYear, semester)"
                >
                  <v-list-item-icon>
                    <v-icon :color="colors[semester.color].color">mdi-checkbox-blank-circle</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ semester?.name }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text>{{ semester.fromDate }} ~ {{ semester.toDate }}</v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list-group>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fiscalYearModal = false"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { LOGOUT, SAVE_PREFERENCE } from '@/store/modules/User/actions';
import { GET_SEMESTERS } from '@/store/modules/Semester/actions';
import { GET_FISCAL_YEARS } from '@/store/modules/FiscalYear/actions';
import { GET_CONFIG } from '@/store/modules/Config/actions';
import TravelTrackerLogo from '@/components/shared/Logo.vue';
import { isUserAccessAllowed } from '@/util/roles';
import { showDevFeatures } from '@/util';

export default {
  name: 'TopBar',
  inject: ['eventHub'],
  components: { TravelTrackerLogo },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      showDevFeatures: showDevFeatures(),
      windowWidth: window.innerWidth,
      semesterModal: false,
      fiscalYearModal: false,
      colors: [
        { index: 0, label: 'Pink', color: 'pink' },
        { index: 1, label: 'Green', color: 'green' },
        { index: 2, label: 'Blue', color: 'blue' },
        { index: 3, label: 'Purple', color: 'deep-purple' },
        { index: 4, label: 'Orange', color: 'orange' },
      ],
      fiscalYears: [],
      selectableFiscalYears: [],
      currentFiscalYear: {
        name: '',
        color: 0,
      },
      selectedFiscalYear: 0,
      semestersByFiscalYearId: {},
      drawer: false,
      group: null,
    };
  },
  async created() {
    this.fetchItems();
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  computed: {
    ...mapGetters('app', ['clientConfig', 'isMobile', 'currentSemester', 'client']),
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['permissions', 'calendarConfig']),
    ...mapGetters('semester', ['semesters']),
    menuItems() {
      const items = [
        {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard',
          to: '/dashboard',
          show: this.showDashboardTab,
        },
        {
          title: 'Trips',
          icon: 'mdi-map-marker-check',
          to: '/trip-requests',
          show: this.showTripRequestTab,
        },
        {
          title: 'Assignments',
          icon: 'mdi-account-check',
          to: '/assignments',
          show:
            this.me.is.superAdmin ||
            this.me.is.childNutritionistDirector ||
            this.me.is.transportationAdmin ||
            this.me.is.limitedAdmin ||
            this.me.is.finance ||
            this.me.is.siteAdmin ||
            this.me.is.siteAdminReadOnly ||
            this.me.is.schoolFinance ||
            this.me.is.vehicleOwner ||
            this.me.is.specialNeedsVehicleOwner ||
            this.me.is.readOnly ||
            this.me.is.fundingManager ||
            this.me.is.approver ||
            this.me.is.requester,
        },
        {
          title: 'Invoices',
          icon: 'mdi-currency-usd',
          to: '/invoices',
          show:
            this.me.is.superAdmin ||
            this.me.is.transportationAdmin ||
            this.me.is.limitedAdmin ||
            this.me.is.finance ||
            this.me.is.schoolFinance ||
            this.me.is.fundingManager ||
            this.me.is.approver ||
            this.me.is.vehicleOwner ||
            this.me.is.specialNeedsVehicleOwner,
        },
        {
          title: 'Calendars',
          icon: 'mdi-calendar',
          to: '/calendars',
          show: this.showCalendarTab,
        },
        {
          title: 'Driver',
          icon: 'mdi-bus',
          to: '/driver',
          show:
            (this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.driver) &&
            this.showDevFeatures &&
            this.clientConfig.data.modules.tier === 'elite',
        },
        // {
        //   title: 'Reports',
        //   icon: 'mdi-file-chart',
        //   to: '/reports',
        //   show: this.showReportsTab,
        // },
      ];
      return items.filter((e) => e.show);
    },
    userMenuItems() {
      const items = [
        [
          {
            title: 'Settings',
            icon: 'mdi-cog-outline',
            to: this.me.is.superAdmin ? '/config/approval-levels' : '/config/trip-request/labels',
            show:
              this.me.is.superAdmin ||
              this.me.is.transportationAdmin ||
              (this.me.is.limitedAdmin && this.me.roles.find((e) => e.roleId == 3).settings),
          },
          {
            title: 'Setup Tables',
            icon: 'mdi-table',
            to: this.onlyDriverTable ? '/tables/drivers/' : '/tables/locations',
            show:
              this.me.is.superAdmin ||
              this.me.is.transportationAdmin ||
              (this.me.is.limitedAdmin && this.me.roles.find((e) => e.roleId == 3).setupTables) ||
              this.me.is.driverTableOnly,
          },
          {
            title: 'Users',
            icon: 'mdi-account-multiple',
            to: '/tables/users',
            show: this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin,
          },
          {
            title: 'Roles',
            icon: 'mdi-security',
            to: '/config/roles',
            show: this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin,
          },
          {
            title: 'Student Roster',
            icon: 'mdi-account-school',
            to: '/tables/roster',
            show: this.clientConfig.data.modules.tier === 'elite' && this.clientConfig.data.modules.roster.sync,
          },
          {
            title: 'Adult Roster',
            icon: 'mdi-human-male-board',
            to: '/tables/adult-roster',
            show:
              this.clientConfig.data.modules.tier === 'elite' &&
              this.clientConfig.data.modules.roster.sync &&
              this.clientConfig.data.modules.roster.adultRoster,
          },
          {
            title: 'Backups',
            icon: 'mdi-cloud-upload',
            to: '/backups',
            show: this.me.is.superAdmin,
          },
          {
            title: 'EZR Sync',
            icon: 'mdi-sync',
            to: '/ezr-sync',
            show: this.me.is.superAdmin && this.clientConfig.data.modules.routing,
          },
          {
            title: 'Import',
            icon: 'mdi-upload',
            to: '/import',
            show: this.me.is.superAdmin,
          },
          {
            title: 'Auditing',
            icon: 'mdi-history',
            to: '/audit',
            show:
              this.me.is.superAdmin ||
              this.me.is.transportationAdmin ||
              this.me.is.limitedAdmin ||
              (this.me.is.limitedAdmin && this.me.roles.find((e) => e.roleId == 3).settings),
          },
        ],
        [
          {
            title: 'My Account',
            icon: 'mdi-account',
            to: '/my-account',
            show: this.clientConfig.data.authentication === 'gdic',
          },
          {
            title: 'System Message',
            icon: 'mdi-message-alert',
            to: '/system-message',
            show: true,
          },
          {
            title: 'Support',
            icon: 'mdi-help-circle',
            href: 'https://ez-support.transact.com/',
            show: this.clientConfig.data && this.clientConfig.data.support.showZoho,
          },
        ],
      ];
      return [items[0].filter((e) => e.show), items[1].filter((e) => e.show)];
    },
    shouldHideTitle() {
      return this.windowWidth < 1600;
    },
    showDashboardTab() {
      if (this.me.roles.length == 1 && (this.me.is.driverTableOnly || this.me.is.reportsOnly) && !this.me.is.approver)
        return false;
      return true;
    },
    showTripRequestTab() {
      return true;
    },
    showCalendarTab() {
      if (this.calendarConfig.showCalendarTab?.includes(9) && this.me.is.approver) return true;
      return this.me.roles.map((e) => e.roleId).some((r) => this.calendarConfig.showCalendarTab?.includes(r));
    },
    showReportsTab() {
      if (this.me.roles.length == 1 && this.me.is.requester && !this.me.is.approver) return false;
      if (this.me.roles.length == 1 && this.me.is.driverTableOnly && !this.me.is.approver) return false;
      if (this.me.roles.length == 2 && this.me.is.requester && this.me.is.driverTableOnly) return false;
      return true;
    },
    canViewSemesterSelector() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin;
    },
    onlyDriverTable() {
      return (
        this.me.is.driverTableOnly &&
        !this.me.is.superAdmin &&
        !this.me.is.transportationAdmin &&
        !this.me.is.limitedAdmin
      );
    },
    fullUrl() {
      const scheme = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
      const site = window.location.hostname;
      const port = window.location.port != '' ? `:${window.location.port}` : '';
      return `${scheme}${site}${port}/${this.client}#`;
    },
  },
  methods: {
    ...mapActions('user', [LOGOUT, SAVE_PREFERENCE]),
    ...mapActions('semester', [GET_SEMESTERS]),
    ...mapActions('fiscalYear', [GET_FISCAL_YEARS]),
    ...mapActions('config', [GET_CONFIG]),
    ...mapMutations('app', ['setCurrentFiscalYear']),
    hasAccess({ module }) {
      const tabsToCheck = ['Driver'];
      if (!tabsToCheck.includes(module)) return true;

      return isUserAccessAllowed({ module, userRoles: this.me.roles });
    },
    async fetchItems() {
      this.fiscalYears = await this.getFiscalYears();
      this.selectableFiscalYears = this.fiscalYears.map((e) => ({ ...e, active: false }));
      this.selectedFiscalYear = this.fiscalYears.findIndex((e) => e.id == this.me.fiscalYearId);
      this.currentFiscalYear = this.fiscalYears[this.selectedFiscalYear];
      this.selectableFiscalYears[this.selectedFiscalYear].active = true;
      this.setCurrentFiscalYear(this.currentFiscalYear);

      for (let fy of this.fiscalYears) {
        this.semestersByFiscalYearId[fy.id] = [];
        for (let sem of this.semesters)
          if (fy.fromDate < sem.toDate && sem.fromDate < fy.toDate) this.semestersByFiscalYearId[fy.id].push(sem);
      }
    },
    async logoutUser() {
      const { protocol, hostname, pathname } = window.location;
      const { done } = await this.logout();
      if (done) window.location.assign(`${protocol}//${hostname}${pathname}#/`);
      window.location.reload();
    },
    async selectSemester(fiscalYear, semester) {
      try {
        const { done } = await this.savePreference({
          ...this.me.data,
          semesterId: semester.id,
          schoolYear: semester.schoolYear,
          fiscalYearId: fiscalYear.id,
        });
        if (done) this.$router.go();
        // TODO: refresh data - not page
      } catch (e) {
        this.$myalert.error('Unable to change School Years / Sessions');
      }
    },
    openSemesterSettings() {
      this.fiscalYearModal = false;
      this.$router.push('/config/semesters');
    },
    openFiscalYearSettings() {
      this.fiscalYearModal = false;
      this.$router.push('/config/fiscal-years');
    },
    updateScreenWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen and (min-width: 1600px) {
  .logo {
    filter: brightness(0) invert(1);
  }
}

.header {
  position: relative;
  background: radial-gradient(circle at top right, #009eec 0%, #00294d 80%, #00294d 100%);
}

.logo {
  max-width: 320px;
  max-height: 60px;
}

.nav-button {
  margin: 0;

  &::after {
    content: '';
    position: absolute;
    background: rgb(247, 142, 30);
    bottom: -4px;
    border-radius: 2px;
    width: 0;
    height: 4px;
    transition: all ease-in-out 0.2s;
  }

  &--active {
    position: relative;

    &::after {
      width: 83%;
    }
  }
}

.nav-list {
  margin-top: 56px;

  .v-icon {
    font-size: 2rem;
  }

  .v-list-item__title {
    font-size: 2rem;
    line-height: 3rem;
  }

  .v-list-item__icon {
    height: 30px;
  }
}

.search-field {
  max-width: 267px;
}

.center-text {
  text-align: center;
}

.z-10 {
  z-index: 10;
}

.w-full {
  width: 100%;
}

.menu-link {
  color: inherit;
  text-decoration: none;
}

.hide-title {
  padding: 10px;
}
</style>
