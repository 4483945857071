export const getters = {
  allTripTypes(state) {
    return state.tripTypes;
  },
  allActiveTripTypes(state) {
    return state.tripTypes.filter((e) => e.active);
  },
  tripTypes(state) {
    return state.tripTypes.filter((e) => e.categoryId && e.active);
  },
  tripTypesById(state) {
    const byId = {};
    state.tripTypes.forEach((e) => (byId[e.id] = e));
    return byId;
  },
  tripTypesByName(state) {
    const byName = {};
    state.tripTypes.forEach((e) => (byName[e.name.toLowerCase()] = e));
    return byName;
  },
};
