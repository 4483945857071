var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c("top-bar", {
        class: _vm.isMobile ? "mobile-wrapper" : "wrapper",
        scopedSlots: _vm._u([
          {
            key: "submenu",
            fn: function () {
              return [_c("router-view", { attrs: { name: "submenu" } })]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-main", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }